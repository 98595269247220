export default {
  forecastList: (state) => state.forecastList,
  financialsList: (state) => state.financialsList,
  availableSaving: (state) => state.availableSaving,
  alreadySaved: (state) => state.alreadySaved,
  loadedModel: (state) => state.loadedModel,
  modelType: (state) => state.modelType,
  isPreviewMode: (state) => state.isPreviewMode,
  hasUpdates: (state) => state.hasUpdates,
  isSaving: (state) => state.isSaving,
  isUpdating: (state) => state.isUpdating,
}
