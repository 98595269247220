export const NEW_NOTIFICATION = 'SET - New notification'
export const DELETE_NOTIFICATION = 'SET - Discard notification'
export const SET_NOTIFICATION_LIST = 'SET - Get notification list'
export const SET_LAST_SEEN = 'SET - Last seen notification'

export default {
  [NEW_NOTIFICATION](state, payload) {
    state.notifications.unshift(payload)
  },
  [SET_LAST_SEEN](state, payload) {
    state.lastSeen = payload
  },
  [SET_NOTIFICATION_LIST](state, payload) {
    const notificationList = payload.map((item, index) => {
      if (state.lastSeen && state.lastSeen[0]) {
        return {
          id: state.lastSeen[0].id,
          data: payload[index],
        }
      } else {
        return {
          id: null,
          data: payload[index],
        }
      }
    })

    state.notifications = notificationList
  },
  [DELETE_NOTIFICATION](state, payload) {
    if (payload && payload.dateTime) {
      const found = state.notifications.findIndex((item) => item.data.dateTime === payload.dateTime)

      if (found > -1) {
        state.notifications.splice(found, 1)
      }
    } else {
      state.notifications = []
    }
  },
}
