export default {
  user: (state) => state.user,
  client: (state) => state.user?.client,
  watchList: (state) => state.watchList,
  preferences: (state) => state.preferences,
  zoomLevel: (state) => state.preferences?.sheetZoom || 1,
  messagePreferences: (state) => state.preferences?.messagePreferences,
  watchListOnly: (state) => state.watchListOnly,
  selectedUniverses: (state) => state.selectedUniverses,
  selectedUniverseIds: (state) => state.selectedUniverses?.map((item) => item.id) || [],
  licenseList: (state) => state.licenseList,
  userGroups: (state) => state?.user?.signInUserSession?.accessToken?.payload?.['cognito:groups'],
  isAdmin: (_, getters) => !!getters.userGroups?.includes('admin'),
  isDataAnalyst: (_, getters) => getters.userGroups?.includes('dataAnalyst'),
  isExperienceAccess: (_, getters) => getters.client?.status === 'experience',
  isTrial: (_, getters) => getters.client?.status === 'trial',
  attributes: (_, getters) => getters?.user?.attributes,
  showMarketData: (_, getters) => getters.client?.hasInstruments,
}
