import GC from '@grapecity/spread-sheets'

/**
 * Default Workbook options
 */
export default {
  calcOnDemand: true,
  allowExtendPasteRange: true,
  iterativeCalculation: true,
  iterativeCalculationMaximumIterations: 24,
  iterativeCalculationMaximumChange: 0.1,
  defaultSheetTabStyles: {
    [GC.Spread.Sheets.SheetTabState.normal]: {
      font: '12px Open Sans',
    },
    [GC.Spread.Sheets.SheetTabState.active]: {
      font: 'bold 12px Open Sans',
    },
  },
}

/**
 * Default sheet preferences
 */
export const defaults = {
  zoom: {
    min: 75,
    max: 125,
  },
  offSets: {
    row: 1,
    col: 1,
  },
  font: {
    size: '12px',
    family: 'Open Sans',
  },
  colWidth: 70,
}

export const headingStylePOJO = {
  hAlign: 1,
  vAlign: 1,
  font: 'bold 12px Open Sans',
  wordWrap: true,
  cellPadding: '10',
}

export const headingStyle = new GC.Spread.Sheets.Style(headingStylePOJO)
