import Auth from '@aws-amplify/auth'
import axios from 'axios'
import NProgress from 'nprogress'
import config from '@/config'

NProgress.configure({ showSpinner: false })

/**
 * Handle API call errors e.g. 401 responses
 * Gets a new token, override the API call headers
 * and push a new request
 */
const handleError = async (error, nprogress) => {
  if (error.message && error.message === 'Network Error') {
    if (nprogress) {
      NProgress.done()
    }

    return
  }
  // only retry if the error is 403 and we suspect the token is expired
  if (error.response.status === 403) {
    error.config.headers['cc-authorization'] = (await Auth.currentSession()).getIdToken().getJwtToken()
    if (nprogress) {
      NProgress.done()
    }
    return axios.request(error.config)
  } else {
    if (nprogress) {
      NProgress.done()
    }
  }
}

/**
 * Creates an axios instance and injects custom headers
 * @param { Object } Config object (nprogress flag, errorHandler)
 */
export const instance = async ({ nprogress = true, errorHandler = null, prefix = '', customHeaders = null } = {}) => {
  const instance = axios.create({ baseURL: `${config.API_DOMAIN_URL}/${prefix ? `${prefix}/` : ''}` })
  const location = new URL(document.location)
  const params = location.searchParams
  const urlToken = params.get('token')

  const defaultHeaders = {
    'Content-Type': 'application/json',
    'cc-authorization': urlToken ? urlToken : (await Auth.currentSession()).getIdToken().getJwtToken(),
  }

  instance.interceptors.request.use(async (config) => {
    config.headers = customHeaders || defaultHeaders
    if (nprogress) {
      NProgress.start()
    }

    return config
  })

  instance.interceptors.response.use(
    (response) => {
      if (nprogress) {
        NProgress.done()
      }

      return response
    },
    async (error) => {
      if (errorHandler) {
        await errorHandler(error, nprogress)
      } else {
        await handleError(error, nprogress)
      }
    },
  )

  return instance
}

export const admin = ({ prefix = 'admin', noBase = false, nprogress = true, customErrorHandler = null } = {}) => {
  const admin = axios.create({ baseURL: noBase ? '' : `${config.API_DOMAIN_URL}/${prefix}/` })
  admin.interceptors.request.use(async (config) => {
    config.headers['cc-authorization'] = (await Auth.currentSession()).getIdToken().getJwtToken()
    if (nprogress) {
      NProgress.start()
    }
    return config
  })

  admin.interceptors.response.use(
    (response) => {
      if (nprogress) {
        NProgress.done()
      }

      return response
    },
    async (error) => {
      if (customErrorHandler) {
        await customErrorHandler(error, nprogress)
      } else {
        await handleError(error, nprogress)
      }
    },
  )

  return admin
}

export const request = async function ({ base = '', auth = '', nprogress = true } = {}) {
  const instance = await axios.create({ baseURL: base })

  instance.interceptors.request.use(async (config) => {
    config.headers = { Authorization: auth }
    if (nprogress) NProgress.start()
    return config
  })

  instance.interceptors.response.use((response) => {
    if (nprogress) {
      NProgress.done()
    }

    return response
  })

  return instance
}
