import { instance } from '@/services/authRequest'
import collapsedTrace from '@/utils/collapsedTrace'
import serializeComparables from '@/utils/comparables/serializeComparables'

export default async (compsType, options) => {
  collapsedTrace('persistComps', compsType, options)
  const persistableProperties = serializeComparables(compsType, options)
  if (persistableProperties === null) {
    return
  }
  const req = await instance({ prefix: 'user-management' })
  req({
    url: `user/comps-settings`,
    method: 'POST',
    data: persistableProperties,
  })
}
