import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes/'

import { beforeEach, beforeResolve, afterEach } from './hooks.js'
import config from '@/config'

const routerOptions = {
  history: createWebHistory(config.RELATIVE_URL_PATH ? `/${config.RELATIVE_URL_PATH}` : undefined),
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
}

const router = createRouter(routerOptions)

router.beforeEach(beforeEach)
router.beforeResolve(beforeResolve)
router.afterEach(afterEach)

export default router
