import {
  SET_ISSUE_TYPE,
  SET_CLASSIFICATION,
  SET_INITIALISING,
  SET_OUTLIERS_INCLUDED,
  SET_HAS_PERSISTED_STATE,
  SET_ORIGINAL_COLUMN_SET,
  SET_CUSTOM_COLUMN_SET,
  SET_FX_SETTING,
  SET_COMPS_TYPE,
  SET_FILTER_ITEM_MAP,
  SET_SORT_INFO,
  RESET_COMPS,
} from './mutations'

// Libs
import gQLMixin, { handleGraphQLErr } from '@/mixins/graphqlMixin.js'

// Graph QL
import * as gqlQuery from './queries.graphql'
import setClassificationFilter from '@/spreadsheets/utils/setClassificationFilter.js'
import getActiveSheet from '@/spreadsheets/getActiveSheet'
import { getDefaultState } from '@/stores/Comparables/state'
import persistComps from '@/utils/comparables/persistComps'

export default {
  setIssueType({ commit, state }, { payload, persist }) {
    commit(SET_ISSUE_TYPE, payload)
    if (persist) {
      persistComps(state.compsType, {
        outliersIncluded: state.outliersIncluded,
        classificationSelected: state.classificationSelected,
        fxSetting: state.fxSetting[state.compsType],
        issueType: state.issueType,
        customColumnSet: state.customColumnSet,
        filterItemMap: state.filterItemMap,
        sortInfo: state.sortInfo,
      })
    }
  },
  setClassificationView({ commit, state }, { payload, persist }) {
    commit(SET_CLASSIFICATION, payload)
    setClassificationFilter(getActiveSheet(), 'GICS Level', payload)
    if (persist) {
      persistComps(state.compsType, {
        outliersIncluded: state.outliersIncluded,
        classificationSelected: state.classificationSelected,
        fxSetting: state.fxSetting[state.compsType],
        issueType: state.issueType,
        customColumnSet: state.customColumnSet,
        filterItemMap: state.filterItemMap,
        sortInfo: state.sortInfo,
      })
    }
  },
  setOutliersIncluded({ commit, state }, { payload, persist }) {
    commit(SET_OUTLIERS_INCLUDED, payload)
    if (persist) {
      persistComps(state.compsType, {
        outliersIncluded: state.outliersIncluded,
        classificationSelected: state.classificationSelected,
        fxSetting: state.fxSetting[state.compsType],
        issueType: state.issueType,
        customColumnSet: state.customColumnSet,
        filterItemMap: state.filterItemMap,
        sortInfo: state.sortInfo,
      })
    }
  },
  setHasPersistedState({ commit }, payload) {
    commit(SET_HAS_PERSISTED_STATE, payload)
  },
  setOriginalColumnSet({ commit }, payload) {
    commit(SET_ORIGINAL_COLUMN_SET, payload)
  },
  setCustomColumnSet({ commit, state }, { payload, persist }) {
    commit(SET_CUSTOM_COLUMN_SET, payload)
    if (persist) {
      persistComps(state.compsType, {
        outliersIncluded: state.outliersIncluded,
        classificationSelected: state.classificationSelected,
        fxSetting: state.fxSetting[state.compsType],
        issueType: state.issueType,
        customColumnSet: state.customColumnSet,
        filterItemMap: state.filterItemMap,
        sortInfo: state.sortInfo,
      })
    }
  },
  async getComparables(unused, payload) {
    if (!payload) {
      return
    }
    let query = 'GetCompanyComparables'
    const { compsType } = payload

    if (compsType) {
      if (compsType === 'sector') {
        query = 'GetSectorComparables'
      } else if (compsType === 'market') {
        query = 'GetMarketComparables'
      }
    }

    try {
      const {
        data: { comparables },
      } = await gQLMixin.methods.communicate(gqlQuery[query], payload)

      return comparables
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  setComparablesInitialisationStatus({ commit }, payload) {
    commit(SET_INITIALISING, payload)
  },
  setFXSetting({ commit, state }, { payload, persist }) {
    commit(SET_FX_SETTING, payload)
    if (persist) {
      persistComps(state.compsType, {
        outliersIncluded: state.outliersIncluded,
        classificationSelected: state.classificationSelected,
        fxSetting: state.fxSetting[state.compsType],
        issueType: state.issueType,
        customColumnSet: state.customColumnSet,
        filterItemMap: state.filterItemMap,
        sortInfo: state.sortInfo,
      })
    }
  },
  setCompsType({ commit }, payload) {
    commit(SET_COMPS_TYPE, payload)
  },
  setFilterItemMap({ commit, state }, { payload, persist }) {
    const relevantFilters = payload.filter((condition) => {
      if (state.compsType === 'sector') {
        return condition.index !== 1
      }
      if (['company', 'market'].includes(state.compsType)) {
        return condition.index !== 2
      }
      return true
    })
    commit(SET_FILTER_ITEM_MAP, relevantFilters)
    if (persist) {
      persistComps(state.compsType, {
        outliersIncluded: state.outliersIncluded,
        classificationSelected: state.classificationSelected,
        fxSetting: state.fxSetting[state.compsType],
        issueType: state.issueType,
        customColumnSet: state.customColumnSet,
        filterItemMap: state.filterItemMap,
        sortInfo: state.sortInfo,
      })
    }
  },
  setSortInfo({ commit, state }, { payload, persist }) {
    commit(SET_SORT_INFO, payload)
    if (persist) {
      persistComps(state.compsType, {
        outliersIncluded: state.outliersIncluded,
        classificationSelected: state.classificationSelected,
        fxSetting: state.fxSetting[state.compsType],
        issueType: state.issueType,
        customColumnSet: state.customColumnSet,
        filterItemMap: state.filterItemMap,
        sortInfo: state.sortInfo,
      })
    }
  },
  reset({ commit, state }, payload) {
    commit(RESET_COMPS, payload)
    if (state.compsType === 'sector' && (payload === 'all' || payload.includes('gics'))) {
      setClassificationFilter(getActiveSheet(), 'GICS Level', getDefaultState().classificationSelected)
    }
    persistComps(state.compsType, {
      outliersIncluded: state.outliersIncluded,
      classificationSelected: state.classificationSelected,
      fxSetting: state.fxSetting[state.compsType],
      issueType: state.issueType,
      customColumnSet: state.customColumnSet,
      filterItemMap: state.filterItemMap,
      sortInfo: state.sortInfo,
    })
  },
}
