import GC from '@grapecity/spread-sheets'
import getColumnIndexByTitle from '@/spreadsheets/utils/getColumnIndexByTitle.js'
import store from '@/stores/index'

/**
 * Filter rows by classification (GICS level)
 * @param { Object } sheet - SpreadJS Sheet instance
 * @param { Number } column - The index for the filter
 * @param { String } filterVal - The filter value
 */
export default function (sheet, columnName, filterVal) {
  if (!sheet) {
    return
  }

  sheet.suspendPaint()
  const col = getColumnIndexByTitle(sheet, columnName)
  const selectedClassification = filterVal ? filterVal : store.getters['Comparables/classificationSelected']

  if (!selectedClassification) {
    return
  }

  const filterSystem = sheet.rowFilter()
  const condition = new GC.Spread.Sheets.ConditionalFormatting.Condition(
    GC.Spread.Sheets.ConditionalFormatting.ConditionType.textCondition,
    {
      compareType: GC.Spread.Sheets.ConditionalFormatting.TextCompareType.equalsTo,
      expected: selectedClassification,
    },
  )

  filterSystem.removeFilterItems(col)
  filterSystem.addFilterItem(col, condition)
  filterSystem.filter(col)
  sheet.showCell(1, 1, GC.Spread.Sheets.VerticalPosition.top, GC.Spread.Sheets.HorizontalPosition.left)

  sheet.resumePaint()
}
