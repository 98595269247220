export const SET_FORECAST_LIST = 'SET - Get the forecast list'
export const UPDATE_FORECAST_LIST = 'UPDATE - Forecast list'
export const DELETE_FORECAST = 'SET - Delete forecast model'
export const SET_SAVING_AVAILABILITY = 'SET - Availability of saving'
export const SET_SAVING_STATUS = 'SET - Is Model Saving'
export const SET_LOADED_MODEL = 'SET - Spreadsheet source model'
export const SET_SAVED = 'SET - Forecast saving state'
export const UPDATE_MODEL = 'SET - Update current custom model'
export const SET_UPDATE_AVAILABLE = 'SET - Update availability info'
export const SET_MODEL_TYPE = 'SET - Model Type'
export const SET_FINANCIAL_VERSION = 'SET - Financial Version'
export const SET_IS_UPDATING = 'SET - Is Updating'

export default {
  [SET_LOADED_MODEL](state, payload) {
    state.loadedModel = payload

    if (payload) {
      // keeping backwards compatibility with forecasts response
      if (payload.links?.download) {
        state.loadedModel.gridLink = payload.links.download.grid
        state.loadedModel.mapLink = payload.links.download.map
      }
      // when a model gets loaded it means the model has been saved at least once
      // this enables the "save on top of an existing model" feature
      state.alreadySaved = true
    }
  },
  /**
   * @param { Object } payload - Contains the list of periods as an array + an error key e.g. { periods: ['FY20'], error: 'Some reason' }
   */
  [SET_UPDATE_AVAILABLE](state, payload) {
    state.hasUpdates = payload
  },
  [SET_SAVING_AVAILABILITY](state, payload) {
    if (payload === state.availableSaving) {
      return
    }

    state.availableSaving = payload
  },
  [SET_SAVING_STATUS](state, payload) {
    if (payload === state.isSaving) {
      return
    }
    state.isSaving = payload
  },
  [SET_SAVED](state, payload) {
    if (payload === state.alreadySaved) {
      return
    }

    state.alreadySaved = payload
  },
  [SET_FORECAST_LIST](state, payload) {
    state.forecastList = payload
  },
  [UPDATE_MODEL](state, payload) {
    state.loadedModel = Object.assign({}, state.loadedModel, payload)
  },
  [DELETE_FORECAST](state, forecastId) {
    const found = state.forecastList.data.findIndex((item) => item.forecastId === forecastId)

    if (found < 0) {
      return
    }

    state.forecastList.data.splice(found, 1)
  },
  [UPDATE_FORECAST_LIST](state, payload) {
    const foundItemIndex = state.forecastList.data.findIndex((forecast) => forecast.forecastId === payload.forecastId)

    if (foundItemIndex > -1) {
      state.forecastList.data = state.forecastList.data.toSpliced(foundItemIndex, 1, {
        ...state.forecastList.data[foundItemIndex],
        title: payload.title,
      })
    }
  },
  [SET_MODEL_TYPE](state, payload) {
    state.modelType = payload
  },
  [SET_FINANCIAL_VERSION](state, { versionId, version, updatedAt }) {
    if (state.loadedModel) {
      state.loadedModel.versionId = versionId
      state.loadedModel.version = version
      // we are making an assumption here but if this is broken we
      // have some bigger backend problems as well.
      state.loadedModel.latestVersion = version
      state.loadedModel.versionCreatedAt = updatedAt
    }
  },
  [SET_IS_UPDATING](state, payload) {
    state.isUpdating = payload
  },
}
