/**
 * Collection of redirect functions to be used to document old redirects (preferably with reference to previous URL schemas)
 */

/**
 * As part of https://cognitive-credit.atlassian.net/browse/UI-309, the schema for pipeline batch diffs has changed from `/admin/batches/diff/pipeline?batch_a=colossal-glasses&batch_b=forthright-broom` to `admin/batches/diff/pipeline/colossal-glasses...forthright-broom/companies?company_id=foo`
 * @param {Vue Router Route} to - original route
 * @returns new route
 */

export const redirectPipelineBatchDiffSchema = (to, from, next) => {
  const { batch_a, batch_b, company_id } = to.query
  if (batch_a && batch_b) {
    const newRoute = Object.assign({}, to, {
      name: 'PipelineDiffList',
      query: { company_id },
      params: { batchA: batch_a, batchB: batch_b },
    })
    next(newRoute)
  } else {
    next()
  }
}
