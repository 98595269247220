import { createStore } from 'vuex'

import Company from '@/stores/Company'
import Spreadsheet from '@/stores/Spreadsheet'
import User from '@/stores/User'
import App from '@/stores/App'
import Notification from '@/stores/Notification'
import Comparables from '@/stores/Comparables'
import Models from '@/stores/Models'
import FeatureFlags from '@/stores/FeatureFlags'

const store = createStore({
  modules: {
    Comparables,
    Company,
    App,
    Spreadsheet,
    Notification,
    Models,
    User,
    FeatureFlags,
  },
})

export default store
