<template>
  <div id="app">
    <Main :class="dynClasses">
      <router-view />
    </Main>
    <cognitive-block class="cc-app__printing">
      <CCLogo class="cc-app__logo" />
      <cognitive-paragraph padding="xl 0">The page you are trying to print can’t be printed.</cognitive-paragraph>
    </cognitive-block>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue'
import eventBus from '@/utils/eventBus'
import { useStore, createNamespacedHelpers } from 'vuex-composition-helpers/dist'
import { useRouter, useRoute } from 'vue-router'
import { configureEndpoint } from '@/composables/graphQL'
import Auth from '@aws-amplify/auth'
import { initRum, switchRum } from '@/mixins/rum.js'
import { setOptions, bootstrap } from 'vue-gtag'
import config from '@/config'
import keyboardEvents from '@/composables/keyboardEvents.js'
// Layout
import Main from '@/layout/Main.vue'
import CCLogo from '@/assets/images/cognitive-credit.svg'

const { useGetters: useUserGetters, useActions: useUserActions } = createNamespacedHelpers('User')
const { user, preferences } = useUserGetters(['user', 'preferences'])
const { wipeUser, getUserPreferences, checkEnabledFeatures, getLicenseList } = useUserActions([
  'wipeUser',
  'getUserPreferences',
  'checkEnabledFeatures',
  'getLicenseList',
])
const { useGetters: useFeatureFlagGetters, useActions: useFeatureFlagsActions } =
  createNamespacedHelpers('FeatureFlags')
const { featureFlagsLoaded } = useFeatureFlagGetters(['featureFlagsLoaded'])
const { getFeatureFlags } = useFeatureFlagsActions(['getFeatureFlags'])

const { useGetters: useAppGetters } = createNamespacedHelpers('App')
const { visibleTerminal } = useAppGetters(['visibleTerminal'])

const store = useStore()
const router = useRouter()
const route = useRoute()
const loadingMinimalData = ref(false)
keyboardEvents()

onMounted(() => {
  eventBus.$on('cc:user:logout', (status = true) => {
    status && logOut()
    switchRum()
  })
  initRum()
})

onBeforeUnmount(() => {
  eventBus.$off('cc:user:logout')
  eventBus.$off('cc:user:login')
})

const analyticsLogged = ref(false)

const dynClasses = computed(() => {
  return {
    'cc-app': route.path !== '/memogen',
    'cc-app--topen': visibleTerminal.value,
  }
})

watch(
  user,
  async (newVal, oldVal) => {
    if (
      !analyticsLogged.value &&
      newVal?.client?.status &&
      !window.Cypress &&
      config.STAGE === 'app' &&
      !newVal?.username?.includes('cognitivecredit')
    ) {
      setOptions({
        user_id: newVal.attributes['custom:userId'],
        user_properties: {
          subscription_status: newVal.client.status,
          client_id: newVal.attributes['custom:clientId'],
        },
      })
      await bootstrap()
      analyticsLogged.value = true
    }
    const userHasChanged = newVal?.username !== oldVal?.username

    if (userHasChanged) {
      // Configure the endpoint once the username changes to avoid race condition issues with the routing
      const authToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      await configureEndpoint(authToken)
    }

    if ((userHasChanged || !preferences.value || !featureFlagsLoaded.value) && !loadingMinimalData.value) {
      loadingMinimalData.value = true
      await getMinimalData()
      loadingMinimalData.value = false
    }
  },
  { deep: true },
)

watch(
  () => route.path,
  (to, from) => {
    unloadStore({ fromPath: from, toPath: to, path: '/text-search', name: 'TextSearch' })
    unloadStore({ fromPath: from, toPath: to, path: '/admin/queries', name: 'Queries' })
    unloadStore({ fromPath: from, toPath: to, path: '/drive', name: 'Notes' })
    unloadStore({ fromPath: from, toPath: to, path: '/drive', name: 'Drive' })
  },
)

const logOut = async () => {
  await Auth.signOut()
  await wipeUser()

  router.push({ name: 'Login' })
}

const getMinimalData = async () => {
  try {
    // checkEnabledFeatures checks the user permissions and getFeatureFlags returns them.
    // These should probably be consolidated to one call
    await getLicenseList()
    await Promise.all([getUserPreferences(), checkEnabledFeatures(), getFeatureFlags()])
  } catch (err) {
    console.error(err)
  }
}

const unloadStore = ({ fromPath, toPath, path, name }) => {
  if (fromPath.indexOf(path) > -1 && toPath.indexOf(path) < 0 && store.hasModule(name)) {
    store.dispatch(`${name}/resetModuleState`)
    store.unregisterModule(name)
  }
}
</script>
<style src="@/assets/css/nprogress.css"></style>

<style lang="scss">
// Custom Vars
$logo-w: 20rem;

@import 'scss/common';

.cc-app {
  @media only print {
    display: none;
  }

  &__printing {
    display: none;

    @media only print {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__logo {
    align-self: center;
    width: $logo-w;
    height: auto;
  }
}
</style>
