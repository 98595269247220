import { AwsRum } from 'aws-rum-web'
import Auth from '@aws-amplify/auth'
import config from '@/config'

const endpoint = `https://monitoring.${config.STAGE}.cognitivecredit.com`

const getStackTrace = function () {
  var stack

  try {
    throw new Error('')
  } catch (error) {
    stack = error.stack || ''
  }

  stack = stack.split('\n').map((line) => line.trim())
  return stack.splice(stack[0] == 'Error' ? 2 : 1)
}

const authConfig = {
  sessionEventLimit: 0,
  sessionSampleRate: 1,
  endpoint,
  telemetries: [
    [
      'http',
      {
        recordAllRequests: true,
        urlsToExclude: [/\*.js/, /\*.svg/],
      },
    ],
    'errors',
  ],
  allowCookies: true,
  signing: false,
  enableXRay: false,
  cookieAttributes: { unique: true },
}

const unauthConfig = {
  sessionEventLimit: 100,
  sessionSampleRate: 1,
  guestRoleArn: config.AWS_RUM_GUEST_ROLE,
  identityPoolId: config.AWS_RUM_UNAUTH_IDENTITY_POOL_ID,
  endpoint,
  telemetries: [
    [
      'http',
      {
        recordAllRequests: true,
        urlsToExclude: [/\*.js/, /\*.svg/],
      },
    ],
    'errors',
  ],
  allowCookies: false,
  signing: false,
  enableXRay: false,
}

export let awsRum
const AUTH_APPLICATION_ID = config.AWS_RUM_AUTH_MONITOR_ID
const UNAUTH_APPLICATION_ID = config.AWS_RUM_UNAUTH_MONITOR_ID
const APPLICATION_VERSION = '1.0.0'
const APPLICATION_REGION = config.AWS_REGION
const originalConsoleWarn = console.warn
const originalConsoleError = console.error

export const initRum = async () => {
  // no need to enable these for local development, especially considering
  // we'll be getting rejected 404s because the domains don't match
  if (!['127.0.0.1', 'localhost'].includes(window.location.hostname)) {
    try {
      await Auth.currentAuthenticatedUser()
      const currentCredentials = await Auth.currentCredentials()
      awsRum = new AwsRum(AUTH_APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, authConfig)

      awsRum.setAwsCredentials(currentCredentials)
    } catch (e) {
      if (e === 'The user is not authenticated') {
        awsRum = new AwsRum(UNAUTH_APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, unauthConfig)
      }
    } finally {
      console.warn = function (...args) {
        awsRum.recordEvent('com.cc.console.warning', {
          message: JSON.stringify(args),
          stack: getStackTrace(),
        })
        originalConsoleWarn.apply(console, args)
      }
      console.error = function (...args) {
        // TODO: Do we want to integrate this with their own
        // registerError method? We are capturing errors...
        awsRum.recordEvent('com.cc.console.error', {
          message: JSON.stringify(args),
          stack: getStackTrace(),
        })
        originalConsoleError.apply(console, args)
      }
    }
  }
}
export const switchRum = async () => {
  if (awsRum) {
    awsRum.dispatch()
    awsRum.disable()
  }
  await initRum()
}

export default {
  awsRum,
  initRum,
  switchRum,
}
