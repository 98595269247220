<template>
  <header v-if="user" class="cc-ui-navigation">
    <nav class="cc-ui-navigation__main">
      <cognitive-list>
        <cognitive-list-item>
          <router-link class="cc-ui-navigation__brand" :to="logoLink" aria-label="Cognitive Credit">
            <Logo class="cc-ui-navigation__logo" />
          </router-link>
        </cognitive-list-item>
        <template v-for="(list, index) in menu" :key="`menu-list-${index}`">
          <cognitive-list-item class="cc-ui-navigation__link">
            <template v-if="list.isExternal">
              <a :href="list.path" :data-test-handle="`cc-ui-navigation-link-${list.path}`">
                {{ list.text }}
              </a>
            </template>
            <template v-else-if="list.subMenu">
              <cognitive-dropdown
                close-on-leave
                overlap
                content-pos="left"
                :data-title="list.title"
                :class="{
                  'cc-ui-navigation__link': true,
                  'is-active': $route?.fullPath.includes(list.path),
                  'cc-ui-navigation__link--disabled': list.disabled,
                }"
                type="button"
                @click="dropdownClickHandler($event, list)"
              >
                <template #buttonText> {{ list.text }}</template>
                <template #blockText>
                  <SubNav :items="list.subMenu" />
                </template>
              </cognitive-dropdown>
            </template>
            <template v-else>
              <router-link :to="list.path" :data-test-handle="`cc-ui-navigation-link-${list.path}`">
                {{ list.text }}
              </router-link>
            </template>
          </cognitive-list-item>
        </template>
        <cognitive-list-item>
          <BetaBanner />
        </cognitive-list-item>
      </cognitive-list>
      <cognitive-list class="cc-ui-navigation__tools">
        <cognitive-tag
          v-if="showTrialBanner"
          size="xs"
          class="cc-ui-navigation__banner-link"
          :bg-color="trialBannerColours.background"
          :text-color="trialBannerColours.text"
        >
          {{ trialBannerText }}
        </cognitive-tag>
        <cognitive-list-item padding="0 sm 0 0" class="cc-ui-navigation__terminal">
          <button @click="toggleTerminal(true)">
            Terminal <span>({{ terminalShortcutString }})</span>
          </button>
        </cognitive-list-item>
        <template v-if="!isAdminPanel">
          <cognitive-list-item padding="0 sm 0 0">
            <cognitive-dropdown close-on-leave overlap content-pos="right" class="cc-ui-navigation__link">
              <template #buttonText> Help </template>
              <template #blockText>
                <HelpDropdown />
              </template>
            </cognitive-dropdown>
          </cognitive-list-item>
        </template>
        <template v-if="companyWatcher">
          <cognitive-list-item padding="0 xs 0 0">
            <cognitive-dropdown
              overlap
              name="Notification centre"
              content-pos="right"
              class="cc-ui-navigation__bell cc-ui-navigation__link"
              :class="{
                'cc-ui-navigation__link--has-notifications': notifications.length > 0,
                'cc-ui-navigation__link--no-notifications': notifications.length <= 0,
              }"
              :data-title-right="notifications.length <= 0 ? 'You currently have no notifications' : null"
            >
              <template #buttonText>
                <cognitive-icon icon-type="css" icon-name="IconNotification" />
              </template>
              <template #blockText>
                <NotificationMenu />
              </template>
            </cognitive-dropdown>
          </cognitive-list-item>
        </template>
        <template v-else>
          <cognitive-list-item padding="0 xs 0 0">
            <cognitive-button
              class="cc-ui-navigation__bell cc-ui-navigation__reconnect"
              version="link"
              data-title-right="Notifications offline, Click to reconnect"
              @click="listenCompanyChanges()"
            >
              <cognitive-icon icon-type="css" icon-name="IconNotificationOffline" />
            </cognitive-button>
          </cognitive-list-item>
        </template>

        <template v-if="isAdmin || isDataAnalyst">
          <template v-if="isAdminPanel">
            <cognitive-list-item class="cc-ui-navigation__link cc-ui-navigation__link--back">
              <router-link to="/companies">Application</router-link>
            </cognitive-list-item>
            <cognitive-list-item v-if="isAdmin" class="cc-ui-navigation__link cc-ui-navigation__link--back">
              <a :href="orgManagementLink">Org Admin</a>
            </cognitive-list-item>
          </template>
          <template v-else>
            <cognitive-list-item class="cc-ui-navigation__link cc-ui-navigation__link--back">
              <router-link to="/admin">Data Admin</router-link>
            </cognitive-list-item>
            <cognitive-list-item v-if="isAdmin" class="cc-ui-navigation__link cc-ui-navigation__link--back">
              <a :href="orgManagementLink">Org Admin</a>
            </cognitive-list-item>
          </template>
        </template>
        <cognitive-list-item>
          <cognitive-dropdown
            close-on-leave
            overlap
            content-pos="right"
            name="My profile"
            class="cc-ui-navigation__link cc-ui-navigation__link--profile"
          >
            <template #buttonText>
              {{ userInitials }}
            </template>
            <template #blockText>
              <ProfileMenu />
            </template>
          </cognitive-dropdown>
        </cognitive-list-item>
      </cognitive-list>
    </nav>
    <template v-if="toggleTerminal">
      <TerminalWrapper v-show="toggleTerminal" />
    </template>
  </header>
</template>

<script>
import { defineAsyncComponent } from 'vue'
// Store
import { mapGetters, mapActions } from 'vuex'

// Utils
import { shortcutString } from '@/utils/device'
import CcColours from '@/utils/colours/colours'
import getWorkBook from '@/spreadsheets/getWorkBook'

// Mixins
import eventTracking from '@/mixins/events'
import eventBus from '@/utils/eventBus'

const Logo = defineAsyncComponent(() => import('@/assets/images/cognitive-credit-compressed.svg'))
export default {
  name: 'CogCredNavigation',
  components: {
    SubNav: defineAsyncComponent(() => import('@/controls/navigation/SubNav.vue')),
    ProfileMenu: defineAsyncComponent(() => import('@/controls/navigation/ProfileMenu.vue')),
    NotificationMenu: defineAsyncComponent(() => import('@/controls/navigation/NotificationMenu.vue')),
    HelpDropdown: defineAsyncComponent(() => import('@/controls/navigation/HelpDropdown.vue')),
    TerminalWrapper: defineAsyncComponent(() => import('@/components/terminal/TerminalWrapper.vue')),
    Logo,
    BetaBanner: defineAsyncComponent(() => import('@/controls/navigation/BetaBanner.vue')),
  },
  mixins: [eventTracking],
  data() {
    return {
      viewHelpMenu: false,
      CcColours,
      viewCompaniesMenu: false,
      viewBatchesMenu: false,
      viewProfileMenu: false,
      terminalShortcut: ['Alt', 'T'],
      menuList: [
        {
          text: 'Companies',
          path: '/companies',
        },
        {
          text: 'Comparables',
          path: '/comparables',
          subMenu: [
            {
              text: 'Company',
              path: '/comparables/company',
            },
            {
              text: 'Sector',
              path: '/comparables/sector',
            },
            {
              text: 'Market',
              path: '/comparables/market',
            },
          ],
        },
        {
          text: 'Drive',
          path: '/drive',
        },
        {
          text: 'Text Search',
          path: '/text-search',
        },
      ],
      adminMenuList: [
        {
          text: 'Companies',
          path: '/admin/companies',
          subMenu: [
            {
              text: 'List',
              path: '/admin/companies',
            },
            {
              text: 'Data stages',
              path: '/admin/companies/distribution',
            },
          ],
        },
        {
          text: 'Documents',
          path: '/admin/documents',
          subMenu: [
            {
              text: 'List',
              path: '/admin/documents',
            },
            {
              text: 'Tools',
              path: '/admin/documents/tools',
            },
          ],
        },
        {
          text: 'Batches',
          path: '/admin/batches',
          subMenu: [
            {
              text: 'List',
              path: '/admin/batches',
            },
            {
              text: 'Diff',
              path: '/admin/batches/diff',
            },
          ],
        },
        {
          text: 'Queries',
          path: '/admin/queries',
        },
        {
          text: 'Data Checks',
          path: '/admin/data-checks',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('User', ['isAdmin', 'isDataAnalyst', 'user', 'isExperienceAccess', 'showMarketData']),
    ...mapGetters('Company', ['companyWatcher', 'terminalNavigationVisible']),
    ...mapGetters('Comparables', ['initialising']),
    ...mapGetters('Notification', ['notifications']),
    ...mapGetters('Modal', ['modals']),
    ...mapGetters('App', ['visibleTerminal']),
    ...mapGetters('FeatureFlags', ['isFeatureFlagEnabled']),
    isAdminPanel() {
      return /\/admin($|.+)/gim.test(this.$route.path)
    },
    orgManagementLink() {
      return `${window.location.origin}/organisation-management`
    },
    userInitials() {
      if (this.user && this.user.attributes.given_name && this.user.attributes.family_name) {
        return `${this.user.attributes.given_name.charAt(0)}${this.user.attributes.family_name.charAt(0)}`
      }
      return 'AN'
    },
    // TODO: This will be changed with newer groups whenever the API is ready
    menu() {
      let list = [...(this.isAdminPanel ? this.adminMenuList : this.menuList)]

      if (!this.isAdminPanel) {
        if (!this.showMarketData) {
          // Remove item for users with different special permissions
          const itemToCleanIndex = list.findIndex((item) => item.text === 'Comparables')
          if (itemToCleanIndex > -1) {
            const itemToClean = list[itemToCleanIndex]
            const cleanedItem = Object.assign({}, itemToClean, {
              subMenu: itemToClean.subMenu.filter((subItem) => subItem.text !== 'Market'),
            })

            list[itemToCleanIndex] = cleanedItem
          }
        }
        if (this.comparablesLinksDisabled) {
          const itemToDisableIndex = list.findIndex((item) => item.text === 'Comparables')
          if (itemToDisableIndex > -1) {
            const itemToDisable = list[itemToDisableIndex]
            const disabledItem = Object.assign({}, itemToDisable, {
              subMenu: itemToDisable.subMenu.map((subItem) => ({ ...subItem, disabled: true })),
            })

            list[itemToDisableIndex] = disabledItem
          }
        }
        if (this.isExperienceAccess) {
          const itemToDisableIndex = list.findIndex((item) => item.text === 'Comparables')
          list[itemToDisableIndex].title = `This feature is restricted for your account type.`
          list[itemToDisableIndex].disabled = true
        }
      } else {
        if (this.isDataAnalyst && !this.isAdmin) {
          list = list.filter((navigationItem) => navigationItem.text === 'Queries')
        }
      }

      return list
    },
    hasVisibileModals() {
      return this.modals.length > 0 && this.modals.findIndex((item) => item.visible) > -1
    },
    logoLink() {
      return this.isAdminPanel ? '/admin' : '/'
    },
    comparablesLinksDisabled() {
      return this.initialising
    },
    terminalShortcutString() {
      return shortcutString(this.terminalShortcut)
    },
    trialBannerColours() {
      if (this.user.client.trialDates.trialDaysRemaining > 5) {
        return {
          background: CcColours.jungleGreen,
          text: CcColours.blackPearl,
        }
      } else if (this.user.client.trialDates.trialDaysRemaining) {
        return {
          background: CcColours.goldenTanoi,
          text: CcColours.blackPearl,
        }
      } else {
        return {
          background: CcColours.brickRed,
          text: CcColours.white,
        }
      }
    },
    trialBannerText() {
      return this.user.client.trialDates.trialDaysRemaining
        ? `Your trial will end in ${this.user.client.trialDates.trialDaysRemaining} ${
            this.user.client.trialDates.trialDaysRemaining > 1 ? 'days' : 'day'
          }`
        : 'Your trial has completed'
    },
    showTrialBanner() {
      return (
        ['trial', 'experience'].includes(this.user.client.status) &&
        typeof this.user.client?.trialDates?.trialDaysRemaining === 'number'
      )
    },
  },
  watch: {
    user: function (newVal) {
      if (newVal) {
        this.listenCompanyChanges()
      }
    },
    terminalNavigationVisible: function (newVal) {
      this.toggleTerminal(newVal)
    },
  },
  created() {
    eventBus.$on('cc:esc:toggle', () => this.handleTerminal())
  },
  beforeUnmount() {
    eventBus.$off('cc:esc:toggle')
  },
  mounted() {
    if (this.user) {
      this.listenCompanyChanges()
    }
  },
  methods: {
    ...mapActions('Company', ['listenCompanyChanges']),
    ...mapActions('App', ['toggleTerminal']),
    ...mapActions('Modal', ['openModal']),
    handleTerminal() {
      const workBook = getWorkBook()
      if (!this.isSpreadSheetFocused()) {
        if (this.hasVisibileModals) {
          // TODO: understand whats going on here
          this.toggleTerminal(false)
        } else {
          this.toggleTerminal(!this.visibleTerminal)

          if (workBook) {
            workBook.focus(!this.visibleTerminal)
          }
        }
      } else {
        if (workBook) {
          workBook.focus(false)
          this.toggleTerminal(true)
        }
      }
    },
    isSpreadSheetFocused() {
      return !!document.activeElement.getAttribute('contenteditable')
    },
    dropdownIsDisabled(item) {
      if (item.text === 'Comparables' && this.isExperienceAccess) {
        return true
      } else {
        return false
      }
    },
    dropdownClickHandler(event, item) {
      if (item.text === 'Comparables' && this.isExperienceAccess) {
        event.target.blur()
        event.preventDefault()
        event.stopImmediatePropagation()
        this.openModal({
          name: 'experienceAccessComparables',
        })
      }
    },
    menuItemAppearsDisabled(item) {
      return item.text === 'Comparables' && this.isExperienceAccess
    },
  },
}
</script>

<style lang="scss">
// Custom vars
$brand-h: 2rem;
$brand-link-w: 2rem;
$brand-w: 1.8125rem;
$profile-initials-w: 2rem;
$user-button-h: 1.75rem;
$nav-logo-h: 1.5rem;
$notification-tooltip-w: 7.5rem;
$initial-h: 3rem;

.cc-ui-navigation {
  position: relative;
  z-index: 4;
  line-height: $lh-sm;
  height: $header-height;
  contain: layout;

  .cc-ui-ddown {
    height: 20px;
    align-self: center;
  }

  & &__link--profile {
    height: 100%;
    .cc-ui-button {
      height: 2rem;
      &:before {
        border-bottom: none;
        outline: none;
      }
    }
  }

  &__terminal {
    button {
      @extend %decoration;
      background: transparent;
      border: none;
      color: $white;
      height: 1.25rem;

      &:hover,
      &:focus {
        &:before {
          transition: width 0.25s ease-in, left 0.25s ease-in;
          left: 5%;
          width: 90%;
        }
      }

      &:focus-visible {
        outline: revert;
        box-shadow: none;
        &:after {
          outline: 5px auto -webkit-focus-ring-color;
        }
      }
    }
  }

  @at-root .is-modal-visible::not('.is-draggable-modal-visible') & {
    @media #{$mq-retina} {
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba($black-pearl, 0.85);
        pointer-events: none;
      }
    }
  }

  @at-root .terminal-open & {
    z-index: 5;
  }

  &__banner-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: $sp-sm $sp-md;
    margin-top: $sp-xs;
    margin-right: $sp-xs;
    max-height: 2rem;
    &:hover {
      filter: brightness(105%);
    }

    .cc-ui-icon {
      height: 17px;
      margin-left: $sp-sm;
    }
  }

  &__main {
    background: $black-pearl;
    padding: $sp-xs $sp-sm;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    @at-root .cc-is-offline & {
      background: $black-pearl--offline;
    }

    > ul {
      display: flex;
      align-self: stretch;

      > li {
        height: 100%;
        display: inline-flex;
        align-items: center;

        > .cc-ui-button {
          color: $white;
          border: 0;
          padding: 0 $sp-xs 0 $sp-sm;
          font-size: $fs-xs;

          span {
            display: none;

            @media #{$mq-lg} {
              display: inline-block;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  &__link {
    position: relative;
    flex: 0 1 auto;
    height: 100%;

    > a {
      @extend %decoration;
      display: flex;
      align-self: center;
      padding: $sp-xs $sp-sm;
      color: $white;
      font-size: $fs-xs;

      &:hover,
      &:focus,
      &.is-active {
        color: $white;

        &:before {
          transition: width 0.25s ease-in, left 0.25s ease-in;
          left: 5%;
          width: 90%;
        }
      }

      &.is-active {
        &:after {
          display: block;
          transition: 0.15s opacity 0.25s ease-in;
        }
      }

      &:focus-visible {
        outline: revert;
        box-shadow: none;
      }
    }

    &.is-active,
    &:hover,
    &:focus {
      .cc-ui-button {
        &:before {
          transition: width 0.25s ease-in, left 0.25s ease-in;
          left: 5%;
          width: 90%;
        }
      }
    }
    &.is-active {
      .cc-ui-button {
        &:after {
          display: block;
          transition: 0.15s opacity 0.25s ease-in;
        }
      }
    }
    &::before {
      white-space: pre-wrap;
      width: 10rem;
    }

    .cc-ui-button {
      @extend %decoration;
      color: $baby-powder;
      border: 0;
      background: transparent;
      padding: $sp-xs $sp-sm;
      font-size: $fs-xs;

      @at-root .cc-ui-navigation__tools & {
        padding: $sp-xs 0;
      }

      &:after {
        display: block;
        transition: 0.15s opacity 0.25s ease-in;
      }
      &:hover {
        .iconNotification {
          &::before {
            background: $porcelain;
          }
        }
      }

      .iconNotification {
        &::before {
          background: $white;
        }
      }

      &:focus {
        &:after {
          outline: 5px auto -webkit-focus-ring-color;
        }
      }

      &:hover,
      &:focus,
      &:focus-visible {
        outline: revert;
        box-shadow: none;
        &:after {
          transition: width 0.25s ease-in, left 0.25s ease-in;
        }
      }
    }

    .cc-ui-ddown__content {
      top: calc(100% - #{$sp-xs});
    }

    &--has-notifications {
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: $sp-sm;
        width: $sp-sm;
        height: $sp-sm;
        border-radius: 50%;
        background: $golden-tanoi;
      }
    }

    &--no-notifications {
      .iconNotification {
        &::before {
          opacity: 0.5;
        }
      }
    }

    &--profile {
      position: relative;
      padding: $sp-xs 0;
      margin-left: $sp-sm;

      > .cc-ui-button {
        padding: 0 0;
        background: $jungle-green;
        color: $black-pearl;
        border-radius: 50%;
        border: 0;
        width: $profile-initials-w;

        @at-root .cc-is-offline & {
          background: $jungle-green--offline;
        }
      }

      .cc-menu-dropdown {
        &:after {
          right: -$sp-xs;
        }
      }
    }
    &--disabled {
      cursor: not-allowed;
      color: $gray-chateau;
      .cc-ui-button {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &__reconnect {
    &.cc-ui-button {
      &:focus,
      &:hover {
        .iconNotificationOffline {
          &::before {
            background: $porcelain;
          }
        }
      }

      .iconNotificationOffline {
        &::before {
          background: $baby-powder;
        }
      }
    }

    &[data-title-right] {
      &:before {
        min-width: $notification-tooltip-w;
      }
    }
  }

  &__controls {
    flex: 0 1 auto;
    text-align: left;
  }

  &__brand {
    display: flex;
    width: $brand-link-w;
    height: $brand-h;
    align-items: center;
  }

  &__logo {
    height: $nav-logo-h;
    width: $brand-w;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    fill: $baby-powder;
  }
  &__bell {
    @at-root .cc-ui-navigation__tools & .cc-ui-button {
      padding: 0;
    }
  }
}

%decoration {
  position: relative;

  &:after {
    content: '';
    width: $sp-sm;
    height: $sp-sm;
    background: $black-pearl;
    border-radius: 50%;
    border: 1px solid $white;
    position: absolute;
    bottom: -$sp-sm;
    left: 45%;
    z-index: 0;
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    bottom: -$sp-xs;
    left: 50%;
    margin: auto;
    border-bottom: 1px solid $white;
    z-index: 0;
  }
}
</style>
