<template>
  <cognitive-block
    :class="{
      'cc-ui-layout': true,
      'cc-ui-layout--login': $route.meta.title === 'Cognitive Credit',
    }"
  >
    <CogCredNavigation />
    <cognitive-block
      type="section"
      :class="{ 'cc-ui-layout__wrapper': true, 'cc-ui-layout__wrapper--hasBackground': isSpreadSheetView }"
    >
      <main :class="dynClasses">
        <slot></slot>
      </main>
      <template v-if="hasError">
        <template v-if="errorDetails?.type === WATCH_LIST_EMPTY_ERROR">
          <cognitive-block class="cc-error cc-error--watchlist-empty">
            <cognitive-paragraph size="lg"> No companies have been added to your watch list yet. </cognitive-paragraph>
            <cognitive-paragraph size="sm">
              Start clicking on the Star icon next to the company name.
            </cognitive-paragraph>
            <WatchlistImagery />
          </cognitive-block>
        </template>
        <template v-else>
          <cognitive-error
            :error-message="errorMessage"
            :error-sub-message="errorSubMessage"
            :error-details="errorDetails"
            :action="openModal"
            :action-args="{ name: 'contactUsModal' }"
          />
        </template>
      </template>
    </cognitive-block>

    <cognitive-modal name="contactUsModal">
      <ContactModal />
    </cognitive-modal>

    <cognitive-modal name="Error">
      <ErrorModal />
    </cognitive-modal>
    <cognitive-modal v-if="isExperienceAccess" name="experienceAccessComparables">
      <ExperienceAccessCompsModal />
    </cognitive-modal>
    <template v-if="isSpreadPage">
      <cognitive-modal titled name="shortcutModal"> <ShortcutModal /> </cognitive-modal>
    </template>
    <template v-if="!user && $route.meta.title !== 'memo-generator' && $route.meta.title !== 'data-inspector'">
      <CogCredFooter />
    </template>
  </cognitive-block>
</template>

<script>
// Libs
import { defineAsyncComponent } from 'vue'
// GraphQL
import graphqlMixin from '@/mixins/graphqlMixin.js'
import WatchlistImagery from '@/assets/images/illustrations/imagery-company-watchlist.svg'

// Store
import { mapGetters, mapActions } from 'vuex'
import { WATCH_LIST_EMPTY_ERROR } from '@/utils/globals'

// Components Sync
import CogCredNavigation from '@/controls/navigation/CogCredNavigation.vue'

// Components Async
const ShortcutModal = defineAsyncComponent(() =>
  import('@/components/spreadsheet/SpreadsheetShortcuts/SpreadsheetShortcuts.vue'),
)
const ContactModal = defineAsyncComponent(() => import('@/components/contact/ContactModal.vue'))
const CogCredFooter = defineAsyncComponent(() => import('@/components/common/Footer/Footer.vue'))
const ErrorModal = defineAsyncComponent(() => import('@/components/common/modals/ErrorModal.vue'))
const ExperienceAccessCompsModal = defineAsyncComponent(() =>
  import('@/components/common/modals/ExperienceAccessCompsModal.vue'),
)

export default {
  name: 'CogCred',
  components: {
    CogCredFooter,
    CogCredNavigation,
    ContactModal,
    ShortcutModal,
    WatchlistImagery,
    ErrorModal,
    ExperienceAccessCompsModal,
  },
  mixins: [graphqlMixin],
  data() {
    return {
      WATCH_LIST_EMPTY_ERROR,
    }
  },
  computed: {
    ...mapGetters('User', ['user', 'isExperienceAccess']),
    ...mapGetters('App', ['hasError', 'errorMessage', 'errorSubMessage', 'errorDetails']),
    dynClasses() {
      return {
        'cc-ui-layout__col': true,
        'cc-ui-layout__col--main': true,
        'cc-ui-layout__col--noScroll': this.$route.path === '/text-search',
        'cc-ui-layout__col--comp': this.$route.path === '/comparables',
      }
    },
    isSpreadPage() {
      return this.$route?.meta?.title?.match(/Financials|Forecasting|Comparables/) ?? false
    },
    isSpreadSheetView() {
      if (this.$route?.meta?.title) {
        const pageTitle = this.$route.meta.title
        return (
          pageTitle.match(/Financials|Comparables/) ||
          (pageTitle.match(/Forecasting/) && pageTitle !== 'Forecasting Home')
        )
      }
      return false
    },
  },
  methods: {
    ...mapActions('Modal', ['openModal']),
  },
}
</script>

<style lang="scss">
// Custom Vars
$login-footer-h: 3.5rem;

.cc-ui-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    @at-root .cc-app--topen & {
      z-index: 0;
    }

    @at-root .cc-ui-layout--login & {
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
        max-height: calc(100vh - 5.25rem);
      }
    }

    // Variants
    &--hasBackground {
      background: $black-pearl;
    }
  }

  &__col {
    // Variants

    &--noScroll {
      overflow: unset;
    }

    &--comp {
      background-color: $porcelain;
    }
  }
}
.cc-error--watchlist-empty {
  flex-direction: column;
}
</style>
