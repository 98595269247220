/**
 * Loop through the given sheet column to get the index of the title
 * @param { Object } sheet - SpreadJS Sheet instance
 * @param { String } title - The column title text
 */
export default function (sheet, title) {
  const sheetColTitles = sheet.getArray(0, 0, 1, sheet.getColumnCount()).flat(1)

  return sheetColTitles.indexOf(title) || -1
}
