import Store from '@/stores/'
import populateUser from '@/utils/populateUser'

export const beforeEach = async (to, from, next) => {
  // Document Title
  if (to.params.company) {
    const name = to.params.company.charAt(0).toUpperCase() + to.params.company.slice(1)
    const page = to.meta.title || to.matched.some((m) => m.meta.title)
    document.title = `${name} ${page}`
  } else if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  switch (to.name) {
    case 'Login':
    case 'Activate':
      if (!Store.getters['User/user']) {
        next()
      } else {
        next('/companies')
      }
      break
    case 'Admin':
      await populateUser(to)
      if (Store.getters['User/isAdmin']) {
        next('/admin/companies')
      } else if (Store.getters['User/isDataAnalyst']) {
        next('/admin/queries')
      }
      break
    case 'Comparables':
    case 'Company Comparables':
    case 'Sector Comparables':
    case 'Market Comparables':
      await populateUser(to)
      if (Store.getters['User/isExperienceAccess']) {
        next('/404')
      }
      break
  }

  // Feature flag check
  if (to.matched.some((m) => m.meta.featureFlagRequired)) {
    if (!Store.getters['FeatureFlags/featureFlagsLoaded']) {
      await Store.dispatch('FeatureFlags/getFeatureFlags')
      if (
        to.matched.every(
          ({ meta }) =>
            !meta.featureFlagRequired || Store.getters['FeatureFlags/isFeatureFlagEnabled'](meta.featureFlagRequired),
        )
      ) {
        next()
      } else {
        next('/')
      }
    }
  }

  if (to.meta.token) {
    to.query.token ? next() : next('/404')
  }

  // Authentication check
  if (to.matched.some((m) => m.meta.auth)) {
    try {
      await populateUser(to)
      // Admin privileges check
      if (to.matched.some((m) => m.meta.admin)) {
        if (Store.getters['User/isAdmin']) {
          next()
        } else if (
          to.matched.filter((route) => route.meta.admin).every((route) => route.meta.dataAnalyst) &&
          Store.getters['User/isDataAnalyst']
        ) {
          next()
        } else {
          next('/companies')
        }
      } else {
        next()
      }
    } catch (err) {
      console.error(err)
      next(`/?redirect=${encodeURIComponent(to.fullPath)}`)
      return
    }
  } else {
    next()
  }
}

export const afterEach = async (to, from, failure) => {
  if (failure) {
    return
  }
  const isSameRoute = to.path === from.path
  const isSameQuery = JSON.stringify(to.query) === JSON.stringify(from.query)
  const isSameAnchor = to.hash === from.hash
  const { path, hash, fullPath } = to
  if (!(isSameRoute && isSameQuery && isSameAnchor)) {
    const payload = { path }
    const queryString = fullPath.indexOf('?')

    if (hash) {
      payload.anchor = hash
    }

    if (queryString > 0) {
      payload.query = fullPath.slice(queryString + 1, fullPath.length)
    }

    if (Store.getters['User/user']) {
      await Store.dispatch('User/navigate', payload)
    }
  }
  // clear leaveToRoute if exists
  if (Store.getters['App/leaveToRoute'] && Store.getters['App/leaveToRoute'].path === to.path) {
    await Store.dispatch('App/setLeaveToRoute', null)
  }
}

export const beforeResolve = async (to, from, next) => {
  // Handle 404
  if (!to.matched.length) {
    return next('/404')
  }
  // Clear Errors from other pages
  if (Store.getters['App/hasError']) {
    await Store.dispatch('App/clearError')
  }

  return next()
}
