export default {
  issueType: (state) => state.issueType,
  classificationSelected: (state) => state.classificationSelected,
  outliersIncluded: (state) => state.outliersIncluded,
  initialising: (state) => state.initialising,
  hasPersistedState: (state) => state.hasPersistedState,
  originalColumnSet: (state) => state.originalColumnSet,
  customColumnSet: (state) => state.customColumnSet,
  fxSetting: (state) => state.fxSetting[state.compsType],
  filterItemMap: (state) => state.filterItemMap,
  sortInfo: (state) => state.sortInfo,
}
