import { SET_FEATURE_FLAGS } from '@/stores/FeatureFlags/mutations'
import * as gqlQuery from './queries.graphql'

import { handleGraphQLErr, useGraphQL } from '@/composables/graphQL'

export default {
  getFeatureFlags: async ({ commit }) => {
    try {
      const { response, error } = await useGraphQL(gqlQuery.GetFeatureFlags)
      if (error) {
        throw error
      }
      const featureFlagArray = response.data.getFeatureFlags.items
      const featureFlags = featureFlagArray.reduce((acc, featureFlag) => {
        acc[featureFlag.id] = featureFlag.state
        return acc
      }, {})
      commit(SET_FEATURE_FLAGS, featureFlags)
    } catch (err) {
      handleGraphQLErr(err)
    }
  },
}
