import { SET_ERROR, TOGGLE_TERMINAL, SET_LEAVE_TO_ROUTE } from './mutations'

export default {
  displayError({ commit }, { hasError = true, message = '', error = {} }) {
    commit(SET_ERROR, { hasError, message, error })
  },
  clearError({ commit }) {
    commit(SET_ERROR, { hasError: false, message: '', error: {} })
  },
  toggleTerminal({ commit }, status) {
    commit(TOGGLE_TERMINAL, status)
  },
  setLeaveToRoute({ commit }, route) {
    commit(SET_LEAVE_TO_ROUTE, route)
  },
}
