/**
 * Check if the browser navigator is from an Apple device
 */
export const isMacintosh = function () {
  return window.navigator.platform.indexOf('Mac') > -1
}

export const metaKey = () => (isMacintosh() ? 'Cmd' : 'Ctrl')
export const composedKey = () => (isMacintosh() ? 'metaKey' : 'ctrlKey')
export const metaKeyEventCode = () => (isMacintosh() ? 'Meta' : 'Control')

const macKeySymbols = {
  Cmd: '⌘',
  Alt: '⌥',
  Shift: '⇧',
  Ctrl: '⌃',
  Enter: '↩',
}
const winKeySymbols = {
  Ctrl: 'Ctrl',
  Alt: 'Alt',
  Shift: 'Shift',
  Meta: 'Meta',
  Enter: 'Enter',
}

export const keySymbol = (key) => {
  const isMac = isMacintosh()
  return isMac ? macKeySymbols[key] : winKeySymbols[key]
}

export const shortcutString = (shortcut = [], spaceBetween = true) => {
  const isMac = isMacintosh()
  const key = metaKey()
  const metaSymbol = keySymbol(key)
  const shiftSymbol = keySymbol('Shift')
  const ctrlSymbol = keySymbol('Ctrl')
  const altSymbol = keySymbol('Alt')
  const enterSymbol = keySymbol('Enter')
  const joinSymbol = isMac ? (spaceBetween ? ' ' : '') : '+'
  return shortcut
    .map((key) => {
      switch (key) {
        case 'Meta':
        case 'Cmd':
          return metaSymbol
        case 'Shift':
          return shiftSymbol
        case 'Ctrl':
          return ctrlSymbol
        case 'Alt':
          return altSymbol
        case 'Enter':
          return enterSymbol
        default:
          return key
      }
    })
    .join(joinSymbol)
}
