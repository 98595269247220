export const SET_USER = 'SET - User identity'
export const UPDATE_USER_IS_FIRST_SIGNIN = 'SET - User first signin'
export const WIPE_USER = 'SET - Wipe user identity'
export const ADD_TO_WATCHLIST = 'SET - Add company to watchlist'
export const CREATE_WATCHLIST = 'SET - Create watchlist'
export const REMOVE_FROM_WATCHLIST = 'SET - Delete company from watchlist'
export const UPDATE_USER_CLIENT = 'SET - Update user client display name'
export const SET_USER_PREFERENCES = 'SET - User preferences'
export const SET_SELECTED_UNIVERSES = 'SET - Selected worlds'
export const SET_UNIVERSES = 'SET - Universes'
export const SET_WATCHLIST_ONLY = 'SET - Watch list visibility'
export const TOGGLE_UNIVERSES = 'SET - Toggle universe status'

export default {
  [SET_USER](state, user) {
    state.user = user
  },
  [UPDATE_USER_IS_FIRST_SIGNIN](state, isFirstSignIn) {
    state.user = Object.assign({}, state.user, { isFirstSignIn: isFirstSignIn })
  },
  [UPDATE_USER_CLIENT](state, client) {
    state.user = Object.assign({}, state.user, { client: Object.assign({}, state.user.client, client) })
  },
  [WIPE_USER](state) {
    state.user = null
  },
  [SET_UNIVERSES](state, payload) {
    state.licenseList = payload
  },
  /**
   * When watchListOnly flag is set to true
   * de-select all the "universes" so the output of
   * any queried list (e.g. the companies list) doesn't get limited
   * to a subset of the watch list
   * @param { Object } payload.userData the user preference information containing the selected universes array
   * @param { Boolean } payload.status toggle the watchListOnly status
   */
  [SET_WATCHLIST_ONLY](state, payload) {
    const { status, license, userData } = payload
    state.watchListOnly = status

    if (status) {
      state.selectedUniverses = []
    } else {
      if (!license) {
        const { selectedUniverses } = userData
        state.selectedUniverses = [...state.licenseList]?.filter(
          (item) => selectedUniverses.indexOf(item.id) > -1 && item,
        )
      } else {
        state.selectedUniverses = [license]
      }
    }
  },
  [TOGGLE_UNIVERSES](state, payload) {
    const foundItemIndex = state.selectedUniverses?.findIndex((item) => item.id === payload.id)
    if (foundItemIndex < 0) {
      state.selectedUniverses.push(payload)
    } else {
      state.selectedUniverses.splice(foundItemIndex, 1)
    }
  },
  [SET_SELECTED_UNIVERSES](state, universes) {
    if (!universes) {
      return
    }

    if (!state.selectedUniverses || !universes.length) {
      state.selectedUniverses = universes
      return
    }

    universes.forEach((universe) => {
      const foundItemIndex = state.selectedUniverses.findIndex((item) => item.name === universe.name)
      if (foundItemIndex < 0) {
        state.selectedUniverses.push(universe)
      }
    })
  },
  [ADD_TO_WATCHLIST](state, payload) {
    if (!payload.id) {
      return
    }

    const companyIndex = state.watchList.findIndex((item) => item.id === payload.id)

    if (companyIndex > -1) {
      return
    } else {
      state.watchList.unshift(payload)
    }
  },
  [REMOVE_FROM_WATCHLIST](state, payload) {
    if (!payload.id) {
      return
    }
    const companyIndex = state.watchList.findIndex((item) => item.id === payload.id)

    if (companyIndex > -1) {
      state.watchList.splice(companyIndex, 1)
    }
  },
  [CREATE_WATCHLIST](state, payload) {
    state.watchList = payload
  },
  [SET_USER_PREFERENCES](state, payload) {
    state.preferences = payload
  },
}
