export default {
  forecastList: [],
  financialsList: [],
  availableSaving: false,
  alreadySaved: false,
  isSaving: false,
  isUpdating: false,
  loadedModel: null,
  modelType: null,
  hasUpdates: false,
  isPreviewMode: false,
}
