import Store from '@/stores/'
import Auth from '@aws-amplify/auth'

export default async (routeTo) => {
  const user = await Auth.currentAuthenticatedUser()

  if (!Store.getters['User/user']) {
    if (routeTo.query.welcome) {
      user.isFirstSignIn = true
    }
    await Store.dispatch('User/setUser', user)
    return await Store.dispatch('User/checkEnabledFeatures')
  } else {
    return await Store.dispatch('User/checkEnabledFeatures')
  }
}
