import config from '@/config.js'

export default {
  methods: {
    async trackCustomEvent(action, area = '', customParams = {}) {
      if (config.STAGE !== 'app' || window.Cypress) {
        return
      }
      this.$gtag.event(action, {
        event_category: area,
        ...customParams,
      })
    },
    async trackUserNavigation(area) {
      const payload = { path: this.$route.path }
      const queryString = this.$route.fullPath.indexOf('?')

      if (area || this.$route.hash) {
        payload.anchor = area ?? this.$route.hash
      }

      if (queryString > 0) {
        payload.query = this.$route.fullPath.slice(queryString + 1, this.$route.fullPath.length)
      }

      if (this.$store.getters['User/user']) {
        await this.$store.dispatch('User/navigate', payload)
      }
    },
  },
}
