import { getDefaultState } from './state'
// Libs
import gQLMixin from '@/mixins/graphqlMixin.js'

import {
  INITIALIZING,
  HIGHLIGHTED_CELL,
  TABLE_TYPES,
  UPDATE_TYPES,
  PIN_DATAPOINT,
  WIPE_PINNED_CHARTS,
  UPDATE_PIN_CHART,
  PIN_CHART,
  AUDITING,
  AUDIT_SELECTION,
  GRID_LOADED,
  LOCAL_LOADED,
  FILTERING,
  OPEN_AUDIT_PANEL,
  OPEN_CHARTS_PANEL,
  TOGGLE_TRACE,
  TOGGLE_GRID_LINES,
  TOGGLE_CHARTING_TYPE,
  SET_HIGHLIGHT_FILTERS,
  ROW_FILTERING,
  TOGGLE_EXPAND_CHART,
  SET_CHART_OPTS,
  OPEN_RANGE_SEARCH,
} from './mutations'

// Graph QL
import * as gqlQuery from './queries.graphql'

export default {
  setTrace({ commit }, payload) {
    commit(TOGGLE_TRACE, payload)
  },
  toggleGridLines({ commit }) {
    commit(TOGGLE_GRID_LINES)
  },
  toggleChartingType({ commit }, payload) {
    commit(TOGGLE_CHARTING_TYPE, payload)
  },
  setInitializationStatus({ commit }, payload) {
    commit(INITIALIZING, payload)
  },
  setLocalLoadedStatus({ commit }, payload) {
    commit(LOCAL_LOADED, payload)
  },
  setGridLoadedStatus({ commit }, payload) {
    commit(GRID_LOADED, payload)
  },
  toggleFilterPanel({ commit }, payload) {
    commit(FILTERING, payload)
  },
  setRowFilteringStatus({ commit }, payload) {
    commit(ROW_FILTERING, payload)
  },
  toggleAuditPanel({ commit }, payload) {
    commit(OPEN_AUDIT_PANEL, payload)
  },
  toggleChartsPanel({ commit }, payload) {
    commit(OPEN_CHARTS_PANEL, payload)
  },
  toggleSearchRangePanel({ commit }, payload) {
    commit(OPEN_RANGE_SEARCH, payload)
  },
  setHighlightedCell({ commit }, payload) {
    commit(HIGHLIGHTED_CELL, payload)
  },
  updateActiveTypes({ commit }, payload) {
    commit(UPDATE_TYPES, payload)
  },
  toggleAuditing({ commit }, payload) {
    commit(AUDITING, payload)
  },
  togglePinDatapoint({ commit }, payload) {
    commit(PIN_DATAPOINT, payload)
  },
  togglePinChart({ commit }, payload) {
    commit(PIN_CHART, payload)
  },
  updatePinnedChart({ commit }, payload) {
    commit(UPDATE_PIN_CHART, payload)
  },
  setAuditSelection({ commit }, payload) {
    commit(AUDIT_SELECTION, payload)
  },
  wipePinnedCharts({ commit }) {
    commit(WIPE_PINNED_CHARTS)
  },
  toggleExpandChart({ commit }, payload) {
    commit(TOGGLE_EXPAND_CHART, payload)
  },
  setChartOptions({ commit }, payload) {
    commit(SET_CHART_OPTS, payload)
  },
  async getSheetTypes({ commit }, payload) {
    try {
      const res = await gQLMixin.methods.communicate(gqlQuery.GetSheetTypes, payload)
      commit(TABLE_TYPES, res.data.getSheetTypeList)
      return res.data.getSheetTypeList
    } catch (err) {
      console.error(err)
      return err
    }
  },
  setHighlightFilters({ commit }, payload) {
    commit(SET_HIGHLIGHT_FILTERS, payload)
  },
  resetHighlightFilters({ commit }) {
    commit(SET_HIGHLIGHT_FILTERS, getDefaultState().currentHighlightFilters)
  },
}
