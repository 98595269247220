import config from '@/config'
export default {
  Auth: {
    endpoint: config.AWS_COGNITO_DOMAIN_URL,
    // REQUIRED - Amazon Cognito Region
    region: config.AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.AWS_USER_POOL_ID,
    identityPoolId: config.AWS_RUM_AUTH_IDENTITY_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: config.AWS_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH', // Required for the migration
  },
  //GraphQL
  aws_appsync_region: config.AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  aws_appsync_graphqlEndpoint: config.GRAPHQL_TRANSFORMATION_LAYER,
  region: config.AWS_REGION,
}
