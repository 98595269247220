import defaultOptions from '@/spreadsheets/utils/defaultOptions'

export default (customColumnSet) => {
  const columns = customColumnSet.map((c) => {
    return {
      visible: c.visible,
      size: c.size === defaultOptions.colWidth ? undefined : c.size,
    }
  })

  const columnConfig = customColumnSet.map((c) => {
    return {
      value: c.title,
    }
  })

  return { columns, columnConfig }
}
