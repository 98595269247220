export default {
  highlightedCell: (state) => state.highlightedCell,
  activeTypes: (state) => state.activeTypes,
  pinnedDataPoints: (state) => state.pinnedDataPoints,
  pinnedCharts: (state) => state.pinnedCharts,
  tableTypes: (state) => state.tableTypes,
  auditing: (state) => state.auditing,
  filtering: (state) => state.filtering,
  rowFiltering: (state) => state.rowFiltering,
  openAuditPanel: (state) => state.openAuditPanel,
  openChartsPanel: (state) => state.openChartsPanel,
  auditSelections: (state) => state.auditSelections,
  initializing: (state) => state.initializing,
  gridLoaded: (state) => state.gridLoaded,
  localLoaded: (state) => state.localLoaded,
  traceDependents: (state) => state.traceDependents,
  tracePrecedents: (state) => state.tracePrecedents,
  showGridLines: (state) => state.showGridLines,
  chartingType: (state) => state.chartingType,
  currentHighlightFilters: (state) => state.currentHighlightFilters,
  expandedCharts: (state) => state.expandedCharts,
  isChartExpanded: (state) => (chartId) => state.expandedCharts.includes(chartId),
  chartOptions: (state) => (chartId) => state.chartOptions[chartId] || {},
  openSearchRange: (state) => state.openSearchRange,
}
