export const getDefaultState = () => {
  return {
    highlightedCell: null,
    activeTypes: { Y: true, Q: true, H: false, L: false, T: false },
    pinnedDataPoints: [],
    pinnedCharts: [],
    auditing: false,
    filtering: false,
    rowFiltering: false,
    openAuditPanel: false,
    openChartsPanel: false,
    auditSelections: [],
    tableTypes: [],
    initializing: false,
    gridLoaded: false,
    localLoaded: null,
    traceDependents: false,
    tracePrecedents: false,
    showGridLines: false,
    chartingType: 'pit', // pit = point in time, sp = scatter, ts = time series
    expandedCharts: [],
    chartOptions: {},
    currentHighlightFilters: {
      troubleshooting: {
        extractedOnly: false,
        affectedByFixup: false,
        affectedByMostRecentDoc: false,
        periodicity: [],
      },
      changes: {
        restatements: false,
        inconsistencies: false,
        conflicts: false,
        userEdits: true,
      },
    },
    openSearchRange: false,
  }
}
export default getDefaultState()
