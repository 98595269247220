export default {
  companyList: (state) => state.companyList,
  newIssuesOnly: (state) => state.newIssuesOnly,
  companyListFetching: (state) => state.companyListFetching,
  companyMap: (state) => state.companyMap,
  currentCompany: (state) => state.currentCompany,
  companyWatcher: (state) => state.companyWatcher,
  gicsList: (state) => state.gicsList,
  gicsClassifications: (_, getters) => {
    return getters.gicsList.reduce((acc, gics) => {
      if (!acc.includes(gics.classification)) {
        acc.push(gics.classification)
      }
      return acc
    }, [])
  },
  gicsByClassification: (_, getters) => {
    const { gicsList } = getters
    const sectors = gicsList.filter((item) => item.code.length < 4)
    const industryGroups = gicsList.filter((item) => item.code.length < 6)
    const industries = gicsList.filter((item) => item.code.length < 8)
    const subIndustries = gicsList.filter((item) => item.code.length >= 8)

    return [...new Set([...sectors, ...industryGroups, ...industries, ...subIndustries])]
  },
  cashSourceList: (state) => state.cashSourceList,
  terminalNavigationVisible: (state) => state.terminalNavigationVisible,
  companyUiInfo: (state) => state.companyUiInfo,
  historyList: (state) => state.historyList,
  mostViewedList: (state) => state.mostViewedList,
  nextReportingList: (state) => state.nextReportingList,
  lastReportedList: (state) => state.lastReportedList,
  companyListCount: (state) => state.companyListCount,
  searchParam: (state) => state.searchParam,
  companySorting: (state) => state.companySorting,
  memoStructureList: (state) => state.memoStructureList,
  companyListError: (state) => state.companyListError,
  companyListPage: (state) => state.companyListPage,
  searchSuggestions: (state) => state.searchSuggestions,
}
