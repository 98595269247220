import GC from '@grapecity/spread-sheets'

import defaultWorkBookOpts from '@/spreadsheets/utils/defaultOptions.js'

let workBook = null

export function setupWorkBook(element, options = defaultWorkBookOpts) {
  if (workBook) {
    throw new Error('WorkBook already exists')
  }
  workBook = new GC.Spread.Sheets.Workbook(element, options)
  return workBook
}
export function resetWorkBook() {
  workBook?.destroy()
  workBook = null
}

export default function () {
  return workBook
}
