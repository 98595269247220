export default {
  RELATIVE_URL_PATH: import.meta.env.VITE_RELATIVE_URL_PATH,
  STAGE: import.meta.env.VITE_STAGE,
  API_DOMAIN_URL: import.meta.env.VITE_API_DOMAIN_URL,
  AWS_REGION: import.meta.env.VITE_AWS_REGION,
  AWS_RUM_AUTH_IDENTITY_POOL_ID: import.meta.env.VITE_AWS_RUM_AUTH_IDENTITY_POOL_ID,
  AWS_RUM_UNAUTH_IDENTITY_POOL_ID: import.meta.env.VITE_AWS_RUM_UNAUTH_IDENTITY_POOL_ID,
  AWS_RUM_GUEST_ROLE: import.meta.env.VITE_AWS_RUM_GUEST_ROLE,
  AWS_RUM_AUTH_MONITOR_ID: import.meta.env.VITE_AWS_RUM_AUTH_MONITOR_ID,
  AWS_RUM_UNAUTH_MONITOR_ID: import.meta.env.VITE_AWS_RUM_UNAUTH_MONITOR_ID,
  AWS_USER_POOL_ID: import.meta.env.VITE_AWS_USER_POOL_ID,
  AWS_COGNITO_DOMAIN_URL: import.meta.env.VITE_AWS_COGNITO_DOMAIN_URL,
  AWS_USER_POOL_WEB_CLIENT_ID: import.meta.env.VITE_AWS_USER_POOL_WEB_CLIENT_ID,
  GRAPHQL_TRANSFORMATION_LAYER: import.meta.env.VITE_GRAPHQL_TRANSFORMATION_LAYER,
  GOOGLE_ANALYTICS_UA: import.meta.env.VITE_GOOGLE_ANALYTICS_UA,
  VISUAL_PING_TOKEN: import.meta.env.VITE_VISUAL_PING_TOKEN,
  LOGROCKET_PROJECT_ID: import.meta.env.VITE_LOGROCKET_PROJECT_ID,
  SPREADJS_KEY: import.meta.env.VITE_SPREADJS_KEY,
  IS_PRODUCTION: import.meta.env.NODE_ENV === 'production',
  DEBUG_EVENT_BUS: import.meta.env.VITE_DEBUG_EVENT_BUS,
}
