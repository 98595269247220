export const SET_ERROR = 'SET - Page Error'
export const TOGGLE_TERMINAL = 'SET - Terminal visibility'
export const SET_LEAVE_TO_ROUTE = 'SET - Leave to route'

export default {
  [SET_ERROR](state, { hasError, message = '', error = {} }) {
    state.hasError = hasError
    state.errorMessage = message
    state.errorSubMessage = error?.message
    state.errorDetails = error
  },
  [TOGGLE_TERMINAL](state, payload) {
    state.visibleTerminal = payload
  },
  [SET_LEAVE_TO_ROUTE](state, payload) {
    state.leaveToRoute = payload
  },
}
