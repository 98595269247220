import defaultState from './state'

export const HIGHLIGHTED_CELL = 'SET - Highlighted cell'
export const UPDATE_TYPES = 'SET - Update active types'
export const TABLE_TYPES = 'SET - List of table types'
export const PIN_DATAPOINT = 'SET - Pin/Unpin data point'
export const PIN_CHART = 'SET - Pin/Unpin chart'
export const UPDATE_PIN_CHART = 'SET - Update pinned chart'
export const WIPE_PINNED_CHARTS = 'SET - Wipe pinned charts'
export const AUDITING = 'SET - Auditing status'
export const AUDIT_SELECTION = 'SET - Audit selection'
export const INITIALIZING = 'SET - Initialize status'
export const GRID_LOADED = 'SET - Status of the grid data injection'
export const FILTERING = 'SET - Filter bottom box'
export const ROW_FILTERING = 'SET - in-sheet row filter'
export const OPEN_AUDIT_PANEL = 'SET - Audit panel'
export const OPEN_CHARTS_PANEL = 'SET - Charts panel'
export const TOGGLE_TRACE = 'SET - Toggle tracing functionality'
export const TOGGLE_GRID_LINES = 'SET - Toggle grid lines'
export const TOGGLE_CHARTING_TYPE = 'SET - Toggle the chart type'
export const SET_HIGHLIGHT_FILTERS = 'SET - Financials filters'
export const LOCAL_LOADED = 'SET - Local upload'
export const TOGGLE_EXPAND_CHART = 'SET - Toggle expand chart'
export const SET_CHART_OPTS = 'SET - Set expanded chart options'
export const OPEN_RANGE_SEARCH = 'SET - Open range search'

export default {
  [TOGGLE_TRACE](state, payload) {
    if (!payload) {
      state.traceDependents = false
      state.tracePrecedents = false
      return
    }

    if (payload === 'dependents') {
      state.traceDependents = !state.traceDependents
    } else {
      state.tracePrecedents = !state.tracePrecedents
    }
  },
  [TOGGLE_GRID_LINES](state) {
    state.showGridLines = !state.showGridLines
  },
  /**
   * @param {*} payload type of chart between: pit, sp, ts (see state)
   */
  [TOGGLE_CHARTING_TYPE](state, payload = 'pit') {
    state.chartingType = payload
  },
  [INITIALIZING](state, payload) {
    state.initializing = payload
  },
  [GRID_LOADED](state, payload) {
    state.gridLoaded = payload
  },
  [LOCAL_LOADED](state, payload) {
    state.localLoaded = payload
  },
  [ROW_FILTERING](state, payload) {
    if (payload === state.rowFiltering) {
      return
    }

    state.rowFiltering = payload
  },
  [FILTERING](state, payload) {
    if (payload === state.filtering) {
      return
    }

    state.filtering = payload
  },
  [OPEN_AUDIT_PANEL](state, payload) {
    if (payload === state.openAuditPanel) {
      return
    }

    state.openAuditPanel = payload
  },
  [OPEN_CHARTS_PANEL](state, payload) {
    if (payload === state.openChartsPanel) {
      return
    }

    state.openChartsPanel = payload
  },
  [OPEN_RANGE_SEARCH](state, payload) {
    if (payload === state.openSearchRange) {
      return
    }

    state.openSearchRange = payload
  },
  [HIGHLIGHTED_CELL](state, payload) {
    state.highlightedCell = payload
  },
  /**
   * Update the active types object in order to reflect the generated
   * audit charts
   * @param { Object } state - the store state
   * @param { Object } payload - the configuration of the types (e.g.  { H:false , L:false ,Q:true, T:false ,Y:true })
   */
  [UPDATE_TYPES](state, payload) {
    state.activeTypes = payload
  },
  [AUDITING](state, payload) {
    state.auditing = payload
  },
  [AUDIT_SELECTION](state, payload) {
    state.auditSelections = payload
  },
  [TABLE_TYPES](state, payload) {
    state.tableTypes = payload
  },
  [WIPE_PINNED_CHARTS](state) {
    state.pinnedDataPoints = null
  },
  /**
   * Add/Remove datapoint from pinnedDataPoints list
   * @param { Object } state - the store state
   * @param { Object } payload - { datapoints: [{}, {}], pin: Boolean }
   */
  [PIN_DATAPOINT](state, payload) {
    if (state.pinnedDataPoints.length < 1) {
      state.pinnedDataPoints.push(payload)
    } else {
      const foundItemIndex = state.pinnedDataPoints.findIndex((auditCard) => {
        return (
          auditCard.data.rowTitle === payload.data.rowTitle &&
          auditCard.data.colTitle === payload.data.colTitle &&
          auditCard.data.text === payload.data.text
        )
      })

      if (foundItemIndex < 0 && payload.pin) {
        state.pinnedDataPoints.push(payload)
      }

      if (foundItemIndex > -1 && !payload.pin) {
        state.pinnedDataPoints.splice(foundItemIndex, 1)
      }
    }
  },
  /**
   * Add/Remove datapoint from pinnedDataPoints list
   * @param { Object } state - the store state
   * @param { Object } payload - { data: [{ value: '' , period: ''}, {}], title: '', tag: '', periodType: 'Q', pin: Boolean }
   */
  [PIN_CHART](state, payload) {
    if (state.pinnedCharts.length < 1) {
      state.pinnedCharts.push(payload)
    } else {
      const foundItemIndex = state.pinnedCharts.findIndex(
        (chart) =>
          JSON.stringify(chart.coords) === JSON.stringify(payload.coords) &&
          chart.title === payload.title &&
          chart.type === payload.type &&
          chart.company === payload.company &&
          chart.currencyIndication === payload.currencyIndication &&
          chart.dataSet?.map((item) => item.category).join() === payload.dataSet?.map((item) => item.category).join() &&
          chart.periodType === payload.periodType,
      )

      if (foundItemIndex < 0 && payload.pin) {
        return state.pinnedCharts.push(payload)
      }

      if (foundItemIndex > -1 && !payload.pin) {
        return state.pinnedCharts.splice(foundItemIndex, 1)
      }
    }
  },
  [UPDATE_PIN_CHART](state, payload) {
    if (state.pinnedCharts.length < 1) {
      return
    }
    const foundItemIndex = state.pinnedCharts.findIndex((chart) => chart.id === payload.id)

    if (foundItemIndex < 0) {
      return
    }

    state.pinnedCharts = state.pinnedCharts.toSpliced(foundItemIndex, 1, {
      ...state.pinnedCharts[foundItemIndex],
      ...payload,
    })
  },
  [SET_HIGHLIGHT_FILTERS](state, payload) {
    if (!payload) {
      state.currentHighlightFilters = {
        ...defaultState.currentHighlightFilters,
      }
    } else {
      state.currentHighlightFilters = payload
    }
  },
  [TOGGLE_EXPAND_CHART](state, payload) {
    if (state.expandedCharts.includes(payload)) {
      state.expandedCharts = state.expandedCharts.toSpliced(state.expandedCharts.indexOf(payload), 1)
    } else {
      state.expandedCharts.push(payload)
    }
  },
  [SET_CHART_OPTS](state, payload) {
    if (state.chartOptions[payload.id]) {
      state.chartOptions[payload.id] = {
        ...state.chartOptions[payload.id],
        ...payload.conf,
      }
    } else {
      state.chartOptions[payload.id] = { ...payload.conf }
    }
  },
}
