import { Amplify } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { graphqlOperation, API } from '@aws-amplify/api'
import awsconfig from '@/auth/aws-exports'
import Store from '@/stores/'
import config from '@/config'

export const handleGraphQLErr = async (err) => {
  if (!err) {
    return
  }
  console.error(`GraphQL Error - ${err.message}`)

  if (err.errors && err.errors[0] && err.errors[0].message && err.errors[0].message.match(/401|custom:clientId/)) {
    try {
      Store.dispatch['User/wipeUser']
      await Auth.signOut()
    } catch (err) {
      console.error('[ERROR] - Unacceptable error, logging out the application for security reasons')
    }
  }
}

export default {
  data() {
    return {
      retries: 0,
    }
  },
  mounted() {
    this.configureEndpoint()
  },
  methods: {
    async configureEndpoint(authToken) {
      const params = new URL(document.location).searchParams
      const urlToken = params.get('token')

      try {
        awsconfig.API = {
          graphql_endpoint: config.GRAPHQL_TRANSFORMATION_LAYER,
          graphql_headers: async () => {
            return {
              Authorization: authToken || urlToken || (await Auth.currentSession()).getIdToken().getJwtToken(),
            }
          },
        }

        Amplify.configure(awsconfig)
      } catch (err) {
        console.error('Error getting the current session', err)
      }
    },
    async communicate(graphql, data = {}) {
      try {
        return await API.graphql(graphqlOperation(graphql, data))
      } catch (err) {
        console.error('[ERROR] Graphql operation', err)

        if (API.isCancel(err)) {
          console.error('User cancelled the request', err.message) // "my message for cancellation"
        }

        return err
      }
    },
    async subscribeToData(graphql, callBack, data = {}, errorHandler) {
      const res = await API.graphql(graphqlOperation(graphql, data))

      return res.subscribe({
        next: (data) => callBack(data),
        error: (error) => {
          if (this.retries < 5) {
            clearTimeout(timedRetry)

            const timedRetry = setTimeout(() => {
              this.subscribeToData(graphql, callBack, data, errorHandler)
              this.retries = this.retries + 1
            }, 10000 * this.retries)
          } else {
            console.error(error)
            if (errorHandler) {
              errorHandler(error)
            }
          }
        },
      })
    },
  },
}
