import config from '@/config'

export const COLUMN_MIN_WIDTH = 75
export const MESSAGE_DISMISS_TIME = 3000
export const LONG_MESSAGE_DISMISS_TIME = 5000
export const WATCH_LIST_EMPTY_ERROR = 'watchListIsEmptyError'

// these are injected through environment variables at buildtime
export const API_URL = config.API_DOMAIN_URL
export const STAGE_URL = config.STAGE

//Search these last n rows for the comps stats or disclaimer
export const SHEET_FOOTER_ROW_COUNT = 10

export const DRIVE_ITEMS_PER_PAGE = 20
export const LONG_TITLE_CHARACTHER_LIMIT = 20
export const SHEET_METADATA_COLUMN = 1
export const SHEET_METADATA_ROW = 1

export const SHEETS_WITH_SUBCONTEXT = ['is', 'cf', 'bs']

export const DEFAULT_TOUR_STEP_OPTIONS = {
  scrollto: {
    behavior: 'smooth',
    block: 'center',
  },
  cancelicon: {
    enabled: true,
    label: 'close tour',
  },
}

// Introduced in DEV-1763 for backwards compatibility purposes, this
// supersedes a logic which displayed (days) as the unit for any tag
// that had the string 'day' in the title; this targets it to only a
// few specific tags - the latter three being the ones in comps.
export const TAGS_MEASURED_IN_DAYS = [
  'Inventory Days',
  'Days in Period',
  'Accounts Receivable Days',
  'Accounts Payable Days',
  'A/R\nDays',
  'Inv\nDays',
  'A/P\nDays',
]

export default {
  MESSAGE_DISMISS_TIME,
  LONG_MESSAGE_DISMISS_TIME,
  COLUMN_MIN_WIDTH,
  WATCH_LIST_EMPTY_ERROR,
  API_URL,
  STAGE_URL,
  SHEET_FOOTER_ROW_COUNT,
  DRIVE_ITEMS_PER_PAGE,
  SHEET_METADATA_COLUMN,
  SHEET_METADATA_ROW,
  TAGS_MEASURED_IN_DAYS,
  LONG_TITLE_CHARACTHER_LIMIT,
  SHEETS_WITH_SUBCONTEXT,
}
