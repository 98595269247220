import { NEW_NOTIFICATION, DELETE_NOTIFICATION, SET_NOTIFICATION_LIST, SET_LAST_SEEN } from './mutations'

// Libs
import gQLMixin, { handleGraphQLErr } from '@/mixins/graphqlMixin.js'

// Graph QL
import * as gqlQuery from './queries.graphql'
import * as qglMutation from './mutations.graphql'

export default {
  newNotification({ commit }, payload) {
    commit(NEW_NOTIFICATION, payload)
  },

  async deleteNotification({ commit, state, dispatch }, payload) {
    commit(DELETE_NOTIFICATION, payload)
    if (payload.id && state.lastSeen && state.lastSeen[0]) {
      if (state.lastSeen[0].id === payload.id) {
        return dispatch('updateLastSeenNotification', {
          id: payload.id,
          data: JSON.stringify({ dateTime: payload.dateTime }),
        })
      }
    }

    return dispatch('setLastSeenNotification', { data: JSON.stringify({ dateTime: payload.dateTime }) })
  },

  async getMissedNotifications({ commit }, dateTime) {
    try {
      const res = await gQLMixin.methods.communicate(gqlQuery.GetMissedNotifications, { dateTime })

      if (res.errors) {
        throw new Error(res.errors)
      }

      commit(SET_NOTIFICATION_LIST, res.data.listNotificationsCollection.items)

      return res.data.listNotificationsCollection.items
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },

  async getLastSeenNotification({ commit }) {
    try {
      const res = await gQLMixin.methods.communicate(gqlQuery.GetLastSeenNotification, {})

      if (res.errors) {
        throw new Error(res.errors)
      }

      commit(SET_LAST_SEEN, res.data.listFlexibleUserStorageCollection.items.reverse())

      return res.data.listFlexibleUserStorageCollection.items
    } catch (err) {
      return err
    }
  },

  /**
   * @param { Object } payload - e.g. { data: { dateTime: timestamp }}
   */
  async setLastSeenNotification({ commit }, payload) {
    try {
      const res = await gQLMixin.methods.communicate(qglMutation.AddLastSeenNotification, payload)
      commit(SET_LAST_SEEN, res.data.createFlexibleUserStorageItemV2.data)

      if (res.errors) {
        throw new Error(res.errors)
      }

      return res.data.createFlexibleUserStorageItemV2.data
    } catch (err) {
      console.error(err)
      return err
    }
  },

  /**
   * @param { Object } payload - e.g. { id: '', data: { dateTime: timeStamp }}
   */
  async updateLastSeenNotification({ commit }, payload) {
    try {
      const res = await gQLMixin.methods.communicate(qglMutation.UpdateLastSeenNotification, payload)

      if (res.errors) {
        throw new Error(res.errors)
      }

      commit(SET_LAST_SEEN, res.data.editFlexibleUserStorageItem)

      return res.data.editFlexibleUserStorageItem
    } catch (err) {
      console.error(err)
      return err
    }
  },

  wipeNotifications({ commit }) {
    commit(SET_NOTIFICATION_LIST, [])
  },
}
