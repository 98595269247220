import getWorkBook from '@/spreadsheets/getWorkBook'

/**
 * Wrapper function to obtain the ranges affected by an undo/redo
 * operation – we need this so we can emit undo/redo events for
 * the appropriate ranges; previously we were emitting them for the
 * 'active' range, but there is no guarantee that the active range
 * is the one affected by the undo/redo operation.
 * @param {Object} operation the undo/redo operation object that is provided as a parameter to the Undo Event
 * @returns {Array} an array of ranges that are affected by the undo/redo operation
 */
export default function getUndoOperationRanges(operation) {
  const operationSheet = getWorkBook()?.getSheetFromName(operation.sheetName)
  if (!getWorkBook() || !operationSheet) {
    console.error('[ERROR] - getUndoOperationRanges cannot find operation sheet')
    return []
  }
  if (operation?.ranges?.length) {
    return operation.ranges.map((range) =>
      operationSheet.getRange(range.row, range.col, range.rowCount, range.colCount),
    )
  }
  return [operationSheet.getRange(operation.row, operation.col, 1, 1, operation.sheetArea)]
}
