import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { graphqlOperation, API } from '@aws-amplify/api'
import awsconfig from '@/auth/aws-exports'
import config from '@/config'
import Store from '@/stores/'

export const configureEndpoint = async (authToken) => {
  const params = new URL(document.location).searchParams
  const urlToken = params.get('token')

  try {
    awsconfig.API = {
      graphql_endpoint: config.GRAPHQL_TRANSFORMATION_LAYER,
      graphql_headers: async () => {
        return {
          Authorization: authToken || urlToken || (await Auth.currentSession()).getIdToken().getJwtToken(),
        }
      },
    }

    Amplify.configure(awsconfig)
  } catch (err) {
    console.error('Error getting the current session', err)
  }
}

export const handleGraphQLErr = async (err) => {
  if (!err) {
    return
  }
  console.error(`GraphQL Error - ${err}`)

  if (err.errors && err.errors[0] && err.errors[0].message && err.errors[0].message.match(/401|custom:clientId/)) {
    try {
      Store.dispatch['User/wipeUser']
      await Auth.signOut()
    } catch (err) {
      console.error('[ERROR] - Unacceptable error, logging out the application for security reasons')
    }
  }
}

export const useGraphQL = async (operation, parameters = {}) => {
  await configureEndpoint()
  let error,
    response = {}
  try {
    response = await API.graphql(graphqlOperation(operation, parameters))
    if (response) {
      if (response.errors) {
        error = response.errors
        throw new Error(response.errors)
      }
      if (response.error) {
        error = response.error
        throw new Error(response.error)
      }
    }
    return { response, error }
  } catch (err) {
    console.error('[ERROR] Graphql operation', err)
    if (err.data && err.errors) {
      return { response: err, error: err.errors }
    }

    if (API.isCancel(err)) {
      console.error('User cancelled the request', err.message) // "my message for cancellation"
    }
    return { response, error: err }
  }
}
