import getActiveSheet from '@/spreadsheets/getActiveSheet'
import separateColumnSet from '@/utils/comparables/separateColumnSet'

export default (
  compsType = 'company',
  {
    outliersIncluded,
    classificationSelected,
    fxSetting,
    issueType,
    sheetName,
    customColumnSet,
    sortInfo,
    filterItemMap,
  },
) => {
  const sheet = getActiveSheet()
  if (!sheet) {
    return
  }
  const { columns, columnConfig } = separateColumnSet(customColumnSet)
  const compsProps = {
    type: compsType,
    filterItemMap,
    sortInfo: sortInfo,
    columnConfig,
    columns,
    activeSheet: sheetName ?? getActiveSheet().name(),
    currency: fxSetting,
  }
  if (compsType === 'sector') {
    compsProps.gicsLevel = classificationSelected
    compsProps.outliersIncluded = outliersIncluded
    delete compsProps.activeSheet
  }
  if (compsType === 'market') {
    compsProps.activeSheet = null
    compsProps.issueType = issueType
    delete compsProps.activeSheet
  }

  // console.timeEnd('serialize workbook')
  return compsProps
}
