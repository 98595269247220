import config from '@/config'

export const relativeLink = (link) => {
  const relativeUrl = config.RELATIVE_URL_PATH
  return `${relativeUrl ? `/${relativeUrl}` : ''}${link}`
}
export const encodeS3Key = (key, prefix = 'documents') =>
  btoa(
    JSON.stringify({
      Document: {
        Key: `${prefix ? `${prefix}/` : ''}${key}`,
      },
    }),
  )
export const resourceLink = (fileKey, redirect = true, prefix) => {
  const apiUrl = config.API_DOMAIN_URL
  let compoundLink = `${apiUrl}/transformation/file?key=${encodeS3Key(fileKey, prefix)}${
    redirect !== undefined ? `&redirect=${redirect}` : ''
  }`
  return compoundLink
}

export const apiLink = (link) => {
  let compoundLink = `${config.API_DOMAIN_URL}${link}`
  return compoundLink
}

export default {
  relativeLink,
  resourceLink,
  encodeS3Key,
  apiLink,
}
