const resizeDirective = {
  mounted(el, binding) {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          const contentBoxSize = entry.contentBoxSize[0]
          binding.value({ inlineSize: contentBoxSize.inlineSize, blockSize: contentBoxSize.blockSize })
        }
      }
    })
    resizeObserver.observe(el)
  },
}
export default resizeDirective
