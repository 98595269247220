/**
 * If you change these, also change _cc-colour-scheme.scss
 */
export const cognitiveColours = {
  white: '#fafafa',
  // Core
  blackPearl: '#041c2c',
  prussianBlue: '#002b49',
  cloudBurst: '#1d2e43',
  jungleGreen: '#279989',
  keppel: '#3ba293',
  tradewind: '#50ab9f',

  // Support
  goldenTanoi: '#ffc658',
  brickRed: '#d32a37',
  punch: '#dd3848',
  jacksonsBlue: '#1e4294',
  stTropaz: '#2d519a',
  grayChateau: '#a9b0b7',
  platinum: '#e8e7ea',
  porcelain: '#f2f3f4',
  babyPowder: '#fcfdfd',
  allPorts: '#007da0',
  curiousBlue: '#2587cc',
  hotCinnamon: '#de5722',
  feijoa: '#a2d780',

  // Sheets set
  carnation: '#f86e6c',
  salmon: '#ff8a5f',
  picasso: '#FFEB95',
  dandelion: '#ffd966',
  koromiko: '#ffbc64',
  celadon: '#a9dd9e',
  chatams: '#115b89',
  havelock: '#60aee2',
  blizzardBlue: '#98daec',
  rockBlue: '#96a8cc',
  nubes: '#bfddf2',
  mischka: '#a5a9b2',
  chateauGreen: '#3Eaf68',
  cannonPink: '#874273',
  highlighterYellow: '#ffff52',
  highlighterGreen: '#31B217',

  // Derived colours
  jungleGreen50: '#95cac3',
  jacksonsBlue75: '#5772af',
  stTropaz50: '#96a8cc',
  stTropaz25: '#cad3e6',
  goldenTanoi70: '#ffd78a',
  salmon75: '#fea68b',
  havelock75: '#88c3e6',
  allPorts50: '#7fbecf',

  // Desatured
  blackPearlOff: '#181818',
  prussianBlueOff: '#242424',
  jungleGreenOff: '#606060',
  tradewindOff: '#7d7d7d',

  alpha: function (colour, alpha) {
    let r = parseInt(colour.slice(1, 3), 16)
    let g = parseInt(colour.slice(3, 5), 16)
    let b = parseInt(colour.slice(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  },
}

export default cognitiveColours
