// Sections
import admin from './admin'
import company from './company'

const routes = [
  {
    path: '/excel-auth',
    name: 'Excel Add-In Authentication',
    component: () => import('@/pages/ExcelAuth.vue'),
    meta: {
      title: 'Cognitive Credit',
      auth: true,
    },
  },
  {
    path: '/activate',
    name: 'Activate User',
    component: () => import('@/pages/ActivateUser.vue'),
    meta: {
      title: 'Cognitive Credit',
      auth: false,
      token: true,
    },
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
    alias: '/index.html',
    meta: {
      title: 'Cognitive Credit',
      auth: false,
    },
  },
  {
    path: '/memogen',
    name: 'Memo Generator',
    component: () => import('@/pages/MemoGen.vue'),
    meta: {
      title: 'memo-generator',
      auth: false,
    },
  },
  {
    path: '/memo',
    name: 'Memo',
    component: () => import('@/pages/MemoEmail.vue'),
    meta: {
      title: 'memo',
      auth: true,
    },
  },
  {
    path: '/inspector',
    name: 'Data Inspector',
    component: () => import('@/components/admin/documents/editor/HTML/HTMLInspector.vue'),
    meta: {
      title: 'data-inspector',
      auth: false,
    },
  },
  {
    path: '/html',
    name: 'HTML View',
    component: () => import('@/pages/HtmlPage.vue'),
    meta: {
      title: 'html-view',
      auth: false,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/pages/404Page.vue'),
  },
  {
    path: '/not-allowed',
    name: 'NotAllowed',
    component: () => import('@/pages/NotAllowed.vue'),
    meta: {
      title: 'Not Allowed',
    },
  },
  {
    path: '/activation-error',
    name: 'Error',
    component: () => import('@/pages/ActivationErrorPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Catch All',
    redirect: '/404',
  },
  {
    path: '/text-search',
    name: 'Text Search',
    component: () => import('@/pages/TextSearch.vue'),
    meta: {
      title: 'Text Search',
      auth: true,
    },
  },
  {
    path: '/drive/:scope?',
    name: 'Drive',
    component: () => import('@/pages/DrivePage.vue'),
    meta: {
      title: 'Drive',
      auth: true,
    },
    children: [
      {
        path: 'note/new',
        name: 'Drive Note Modal New',
        component: () => import('@/components/drive/DriveModals.vue'),
        meta: {
          title: 'Drive - New Note',
          auth: true,
        },
      },
      {
        path: 'note/:noteId',
        name: 'Drive View Note Modal',
        component: () => import('@/components/drive/DriveModals.vue'),
        meta: {
          title: 'Drive - View Note',
          auth: true,
        },
      },
      {
        path: 'note/:noteId/edit',
        name: 'Drive Note Modal Edit',
        component: () => import('@/components/drive/DriveModals.vue'),
        meta: {
          title: 'Drive - Edit Note',
          auth: true,
        },
      },
      {
        path: 'file/:fileId/rename',
        name: 'Drive File Rename Modal',
        component: () => import('@/components/drive/DriveModals.vue'),
        meta: {
          title: 'Drive - Rename File',
          auth: true,
        },
      },
      {
        path: 'version-history/:model',
        name: 'Drive Version History Modal',
        component: () => import('@/components/drive/DriveModals.vue'),
        meta: {
          title: 'Drive - Version History',
          auth: true,
        },
      },
      {
        path: ':fileId/rename',
        name: 'Drive Rename Modal',
        component: () => import('@/components/drive/DriveModals.vue'),
        meta: {
          title: 'Drive - Rename FIle',
          auth: true,
        },
      },
    ],
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('@/pages/Companies.vue'),
    meta: {
      title: 'Companies',
      auth: true,
    },
    children: [
      {
        path: 'list',
        alias: '',
        component: () => import('@/components/companies/CompanyList/CompanyList.vue'),
        meta: {
          title: 'Companies',
          auth: true,
        },
      },
      {
        path: 'request-coverage',
        name: 'Request Coverage',
        meta: {
          title: 'Request Coverage',
          auth: true,
        },
        component: () => import('@/components/companies/CompaniesRequestCoverage/CompaniesRequestCoverage.vue'),
      },
    ],
  },
  {
    path: '/comparables',
    name: 'Comparables',
    component: () => import('@/pages/Comparables.vue'),
    redirect: '/comparables/company',
    meta: {
      title: 'Company Comparables',
      auth: true,
    },
    children: [
      {
        path: 'company',
        name: 'Company Comparables',
        component: () => import('@/pages/comparables/Company.vue'),
        props: (route) => ({
          companyId: route.query.id,
        }),
        meta: {
          title: 'Company Comparables',
          auth: true,
        },
      },
      {
        path: 'market',
        name: 'Market Comparables',
        component: () => import('@/pages/comparables/Market.vue'),
        props: (route) => ({
          companyId: route.query.id,
        }),
        meta: {
          title: 'Market Comparables',
          auth: true,
        },
      },
      {
        path: 'sector',
        name: 'Sector Comparables',
        component: () => import('@/pages/comparables/Sector.vue'),
        props: (route) => ({
          companyId: route.query.id,
        }),
        meta: {
          title: 'Sector Comparables',
          auth: true,
        },
      },
    ],
  },
  {
    path: '/profile',
    component: () => import('@/pages/Profile.vue'),
    redirect: '/profile/preferences',
    meta: {
      title: 'Profile',
      auth: true,
    },
    children: [
      {
        path: 'my-team',
        component: () => import('@/pages/profile/MyTeam.vue'),
        meta: {
          title: 'Profile',
          auth: true,
        },
      },
      {
        path: 'preferences',
        component: () => import('@/pages/profile/Preferences.vue'),
        meta: {
          title: 'Profile',
          auth: true,
        },
      },
    ],
  },
  {
    path: '/help',
    component: () => import('@/pages/help/HelpPage.vue'),
    redirect: '/help/update-financials',
    meta: {
      title: 'Help',
      auth: true,
    },
    children: [
      {
        path: 'update-financials',
        component: () => import('@/pages/help/UpdateFinancials.vue'),
        meta: {
          title: 'Help - Update Financials',
          auth: true,
        },
      },
    ],
  },
  {
    path: '/document/:extension/:id/:page?',
    component: () => import('@/pages/DocumentPage.vue'),
    meta: {
      title: 'Document',
      auth: true,
    },
  },
]

const routing = [...routes, ...admin, ...company]

export default routing
