// Mutations
import {
  SET_USER,
  WIPE_USER,
  ADD_TO_WATCHLIST,
  REMOVE_FROM_WATCHLIST,
  CREATE_WATCHLIST,
  SET_USER_PREFERENCES,
  UPDATE_USER_CLIENT,
  SET_SELECTED_UNIVERSES,
  SET_UNIVERSES,
  TOGGLE_UNIVERSES,
  SET_WATCHLIST_ONLY,
  UPDATE_USER_IS_FIRST_SIGNIN,
} from './mutations'

// Libs
import gQLMixin, { handleGraphQLErr } from '@/mixins/graphqlMixin.js'

// Graph QL
import * as gqlQuery from './queries.graphql'
import * as gqlMutation from './mutations.graphql'
import { instance } from '@/services/authRequest'

export default {
  async navigate(unused, payload) {
    try {
      await gQLMixin.methods.communicate(gqlMutation.Navigate, payload)
    } catch (err) {
      console.error('[ERROR] Logging user navigation', err)
      return err
    }
  },
  async setUser({ commit }, user) {
    commit(SET_USER, user)
  },
  async setUserIsFirstSignIn({ commit }, isFirstSignIn) {
    commit(UPDATE_USER_IS_FIRST_SIGNIN, isFirstSignIn)
  },
  toggleWatchList({ commit, state, dispatch }, payload) {
    const { status, license } = payload
    commit(SET_WATCHLIST_ONLY, { status, license, userData: state.preferences })

    if (license?.id) {
      dispatch('setNewPreference', {
        universes: [license.id],
      })
    }
  },
  async checkEnabledFeatures({ commit }) {
    try {
      const req = await instance({ prefix: 'user-management' })
      const clientRes = await req.get('/client')
      const clientData = clientRes.data.client
      commit(UPDATE_USER_CLIENT, {
        displayName: clientData.displayName,
        loanTradingDesk: clientData.loanTradingDesk,
        status: clientData.status,
        trialDates: clientData.trialDates,
        hasInstruments: clientData.featureInstruments,
      })
    } catch (err) {
      console.error('Error getting the feature flag permissions', err)
      return err
    }
  },
  wipeUser({ commit }) {
    commit(WIPE_USER)
  },
  async requestUpdateStatus(unused, payload) {
    const query = payload.type === 'financials' ? gqlQuery.GetUpdateStatus : gqlQuery.GetForecastUpdateStatus
    try {
      const res = await gQLMixin.methods.communicate(query, { batchId: payload.batchId })
      return res.data.updateStatus
    } catch (err) {
      console.error('[ERROR] Requesting the update status of the forecast model', err)
      return err
    }
  },
  async toggleLicense({ commit, dispatch, getters }, payload) {
    commit(TOGGLE_UNIVERSES, payload)

    dispatch('setNewPreference', {
      universes: getters['selectedUniverseIds'],
    })
  },
  async getLicenseList({ commit }) {
    try {
      const {
        data: { licenseList },
      } = await gQLMixin.methods.communicate(gqlQuery.GetLicenseList)

      commit(SET_UNIVERSES, licenseList)
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
  async getSelectedUniverses({ commit, state, getters }) {
    if (!state.licenseList?.length) {
      return
    }

    const universes = getters['preferences'].selectedUniverses
    const initialUniverses = state.licenseList?.filter((universe) => universes.indexOf(universe.id) > -1 && universe)
    commit(SET_SELECTED_UNIVERSES, initialUniverses)
  },
  /**
   * @param { Array } payload - the list of universe
   * @param { Object } payload[0] - { id: String, name: String, availableToMe: Boolean }
   */
  setSelectedUniverses({ commit }, payload) {
    commit(SET_SELECTED_UNIVERSES, payload)
  },
  async setNewPreference({ commit }, payload) {
    try {
      const req = await instance({ prefix: 'user-management' })
      const { data } = await req({ method: 'patch', url: '/me/preferences', data: payload })

      if (!data) {
        throw new Error(`[ERROR] Couldn't save the preference`)
      }

      commit(SET_USER_PREFERENCES, data)
    } catch (err) {
      console.error('[ERROR] Setting the user preference', err)
      return err
    }
  },
  async getUserPreferences({ commit, state, dispatch }) {
    try {
      const req = await instance({ prefix: 'user-management' })
      const res = await req.get('/user/preferences')
      if (!res.data) {
        throw 'The user preferences object is not being returned as expected'
      }

      commit(SET_USER_PREFERENCES, res.data)
      if (!state.watchListOnly && !state.selectedUniverses?.length) {
        dispatch('getSelectedUniverses')
      }
      return res.data
    } catch (err) {
      console.error('[ERROR] Getting the user preferences', err)
      return err
    }
  },
  async addToWatchList({ commit }, company) {
    try {
      const {
        data: { companyAdded },
      } = await gQLMixin.methods.communicate(gqlMutation.AddToWatchList, { company })

      commit(ADD_TO_WATCHLIST, companyAdded)
      return companyAdded
    } catch (err) {
      console.error(err)
      return err
    }
  },
  async removeFromWatchList({ commit }, company) {
    try {
      const res = await gQLMixin.methods.communicate(gqlMutation.RemoveFromWatchList, { company })
      commit(REMOVE_FROM_WATCHLIST, company)
      return res.data
    } catch (err) {
      console.error(err)
      return err
    }
  },
  async getWatchList({ commit }) {
    try {
      const {
        data: { watchList },
        errors,
      } = await gQLMixin.methods.communicate(gqlQuery.GetWatchList, {})

      if (errors) {
        throw new Error(errors)
      }

      commit(CREATE_WATCHLIST, watchList.data)
      return watchList.data
    } catch (err) {
      handleGraphQLErr(err)
      return err
    }
  },
}
