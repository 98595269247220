// Mutations
import {
  SET_FORECAST_LIST,
  DELETE_FORECAST,
  SET_SAVED,
  SET_SAVING_AVAILABILITY,
  UPDATE_MODEL,
  SET_UPDATE_AVAILABLE,
  SET_MODEL_TYPE,
  UPDATE_FORECAST_LIST,
  SET_LOADED_MODEL,
  SET_SAVING_STATUS,
  SET_FINANCIAL_VERSION,
  SET_IS_UPDATING,
} from './mutations'

// Libs
import gQLMixin from '@/mixins/graphqlMixin.js'

// Graph QL
import * as gqlQuery from './queries.graphql'
import * as gqlMutation from './mutations.graphql'

export default {
  setLoadedModel({ commit }, payload) {
    commit(SET_LOADED_MODEL, payload)
  },
  async deleteForecast({ commit }, forecastId) {
    try {
      const { data } = await gQLMixin.methods.communicate(gqlMutation.DeleteForecast, { forecastId })

      if (!data?.deleteForecast?.deleted) {
        throw new Error('An error occurred while deleting the model')
      }

      commit(DELETE_FORECAST, forecastId)
      return data
    } catch (err) {
      console.error('[ERROR] The deletion of the model failed', err)
      return err
    }
  },
  /**
   * @param { Object } payload - e.g. { gridId: '', shared: false }
   */
  async shareForecast({ state, commit }, payload) {
    try {
      const res = await gQLMixin.methods.communicate(gqlMutation.ShareForecast, payload)

      if (res.errors) {
        throw new Error(res.errors)
      }
      if (state.loadedModel && payload.forecastId === state.loadedModel.id) {
        commit(UPDATE_MODEL, { forecastId: payload.forecastId, shared: payload.shared })
      }
      return res.data.shareForecast
    } catch (err) {
      console.error(err)
      return err
    }
  },
  /**
   * @param { Object } payload - e.g. { id: '', shared: false, title: 'Blah', data: {...}}
   */
  async updateForecast({ state, commit }, payload) {
    try {
      const res = await gQLMixin.methods.communicate(gqlMutation.EditForecast, payload)

      if (res.errors) {
        throw new Error(res.errors)
      }
      if (state.loadedModel && payload.id === state.loadedModel.forecastId) {
        commit(UPDATE_MODEL, payload)
      }
      return res.data.editFlexibleUserStorageItem
    } catch (err) {
      console.error(err)
      return err
    }
  },
  /**
   * The partial update means the data of the model has been already mutated on
   * the DB but it is not within the state of the client application
   */
  async partialUpdateModel({ state, commit }, payload) {
    if (!payload?.forecastId && !payload?.financialId) {
      return
    }
    if (state.loadedModel) {
      commit(UPDATE_MODEL, payload)
    }
  },
  setSavedStatus({ commit }, payload) {
    commit(SET_SAVED, payload)
  },
  setSavingStatus({ commit }, payload) {
    commit(SET_SAVING_STATUS, payload)
  },
  setIsModelSavable({ commit }, payload) {
    commit(SET_SAVING_AVAILABILITY, payload)
  },
  setUpdateAvailable({ commit }, payload) {
    commit(SET_UPDATE_AVAILABLE, payload)
  },
  async checkUpdateAvailability({ commit }, payload) {
    if (!payload) {
      return
    }
    const { forecastId, financialVersionId, companyId, periods, periodType } = payload

    try {
      const {
        data: { updateInfo },
        errors,
      } = await gQLMixin.methods.communicate(gqlQuery.CheckUpdateAvailability, {
        forecastId,
        financialVersionId,
        companyId,
        periods,
        periodType,
      })

      if (errors) {
        throw new Error(errors)
      }

      if (updateInfo.error) {
        commit(SET_UPDATE_AVAILABLE, { error: updateInfo.error })
      }

      if (updateInfo.periods) {
        commit(SET_UPDATE_AVAILABLE, updateInfo)
      }
      return updateInfo
    } catch (err) {
      console.error(err)
      return err
    }
  },
  async getForecasts({ commit }, { companyId, filter = null, search, limit = 1000, offset = 0 }) {
    try {
      const res = await gQLMixin.methods.communicate(gqlQuery.GetForecastList, {
        companyId,
        search,
        filter,
        limit,
        offset,
      })

      if (res.errors) {
        throw new Error(res.errors)
      }
      commit(SET_FORECAST_LIST, res.data.forecastList)
      return res.data.forecastList
    } catch (err) {
      console.error(err)
      commit(SET_FORECAST_LIST, {})
      return err
    }
  },
  async getForecastByID({ commit }, forecastId) {
    try {
      const {
        data: { forecastDetail },
        errors,
      } = await gQLMixin.methods.communicate(gqlQuery.GetForecastByID, {
        forecastId,
      })

      if (errors || forecastDetail?.error) {
        throw new Error(`The forecast getter failed to retrieve the expected data – ${errors || forecastDetail?.error}`)
      }
      commit(SET_LOADED_MODEL, forecastDetail?.data)
      return forecastDetail?.data
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  async getFinancialsById({ commit }, payload) {
    try {
      const {
        data: { getFinancialVersion },
        errors,
      } = await gQLMixin.methods.communicate(gqlQuery.GetFinancialsVersion, payload)

      if (errors || getFinancialVersion?.error) {
        const error = errors || getFinancialVersion?.error
        throw new Error(`[ERROR] The financials getter failed to retrieve the expected data:  ${error}`)
      }
      commit(SET_LOADED_MODEL, getFinancialVersion?.data)
      return getFinancialVersion?.data
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  setModelType({ commit }, payload) {
    commit(SET_MODEL_TYPE, payload)
  },
  /**
   * Send the request to update the forecast model list
   * @param {Object} payload object composed by { id, title}
   */
  updateForecastList({ commit }, payload) {
    commit(UPDATE_FORECAST_LIST, payload)
  },
  async createStorageReference({ commit }, payload) {
    try {
      const res = await gQLMixin.methods.communicate(gqlMutation.CreateStorageReference, payload)

      if (res.data?.storageRef?.data) {
        commit(UPDATE_MODEL, res.data.storageRef.data)
      } else {
        throw res.data?.storageRef?.error
      }

      return res.data.storageRef.data
    } catch (err) {
      console.error('[ERROR] Saving the forecast model', err)
      throw err
    }
  },
  async updateExistingForecastFlexibleStorage(unused, payload) {
    try {
      const res = await gQLMixin.methods.communicate(gqlMutation.UpdateSavedForecast, payload)

      return res.data.editFlexibleUserStorageItem
    } catch (err) {
      console.error('[ERROR] Updating the existing forecast model', err)
      return err
    }
  },
  async updateExistingForecastTable(unused, payload) {
    try {
      const res = await gQLMixin.methods.communicate(gqlMutation.EditSavedForecast, payload)

      if (res.error) {
        throw res.error
      }

      return res
    } catch (err) {
      console.error('[ERROR] Updating the existing forecast model', err)
      return err
    }
  },
  saveFinancialVersion({ commit }, payload) {
    try {
      commit(SET_FINANCIAL_VERSION, payload)
    } catch (err) {
      console.error('[ERROR] - saving financial version model', err)
    }
  },
  setIsUpdating({ commit }, payload) {
    commit(SET_IS_UPDATING, payload)
  },
}
